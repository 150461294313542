import React from 'react';

import privacyPolicy from '../assets/file/privacy-policy.pdf';
import termsOfUse from '../assets/file/terms-of-use.pdf';

const Footer = () => {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <footer>
      <div>
        &copy; Copyright {year} Ashbury Systems, LLC. All rights reserved.
        <ul>
          <li>
            <a href={termsOfUse} target={'_blank'} rel="noopener noreferrer">
              Terms Of Use
            </a>
          </li>
          <li>|</li>
          <li>
            <a href={privacyPolicy} target={'_blank'} rel="noopener noreferrer">
              Privacy Policy
            </a>
          </li>
          <li>|</li>
          <li>
            <a href="mailto:help@lista.io" rel="noopener noreferrer">
              help@lista.io
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
