import 'react-quill/dist/quill.snow.css';

import Interweave from 'interweave';
import mediumZoom from 'medium-zoom';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ReactQuill from 'react-quill';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { Field, reduxForm } from 'redux-form';

import { fetch } from '../../utils/dataAccess';
import CurrentUserCan from '../CurrentUserCan';
import SecureFile from '../SecureFile';
import SecureImage from '../SecureImage';
import UsedProcedureStepTreeFormFields from './UsedProcedureStepTreeFormFields';

class Form extends Component {
  state = {
    hasFirstTag: (this.props.initialValues
      ? this.props.initialValues.tagNames
      : []
    ).length
  };

  zoom = mediumZoom();

  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  renderField = data => {
    data.input.className = 'form-control';

    const isInvalid = data.meta.touched && !!data.meta.error;
    if (isInvalid) {
      data.input.className += ' is-invalid';
      data.input['aria-invalid'] = true;
    }

    if (this.props.error && data.meta.touched && !data.meta.error) {
      data.input.className += ' is-valid';
    }

    return (
      <div className={`form-group used-procedure-title`}>
        {data.input.name === 'procedureName' ? (
          <label
            htmlFor={`usedprocedure_${data.input.name}`}
            className="form-control-label w-100 editing-using-procedure-label"
          >
            {/* Using Procedure */}
            Using Template
          </label>
        ) : (
          ''
        )}
        <input
          {...data.input}
          type={data.type}
          step={data.step}
          required={data.required}
          placeholder={data.placeholder}
          id={`usedprocedure_${data.input.name}`}
          style={data.style}
          readOnly={true}
        />
        {isInvalid && <div className="invalid-feedback">{data.meta.error}</div>}
      </div>
    );
  };

  renderDateField = data => {
    data.input.className = 'form-control';

    const isInvalid = data.meta.touched && !!data.meta.error;
    if (isInvalid) {
      data.input.className += ' is-invalid';
      data.input['aria-invalid'] = true;
    }

    if (this.props.error && data.meta.touched && !data.meta.error) {
      data.input.className += ' is-valid';
    }

    let date = data.input.value;
    if (date.indexOf('/') !== -1) {
      let [month, day, year] = date.split('/');
      date = year + '-' + month + '-' + day;
    }

    return (
      <div className={`form-group usedprocedure-${data.input.name}-wrapper`}>
        <label
          htmlFor={`usedprocedure_${data.input.name}`}
          className="form-control-label"
        >
          {data.label}
        </label>
        <input
          {...data.input}
          value={date}
          type={data.type}
          step={data.step}
          required={data.required}
          //placeholder={data.placeholder}
          id={`usedprocedure_${data.input.name}`}
          style={data.style}
          readOnly={!!data.readOnly}
          onClick={event => this.handleClick(event, data)}
          onBlur={() => this.handleBlur()}
        />
        {isInvalid && <div className="invalid-feedback">{data.meta.error}</div>}
      </div>
    );
  };

  renderTitleField = data => {
    //data.input.className = 'form-control';
    data.input.className = '';

    const isInvalid = data.meta.touched && !!data.meta.error;
    if (isInvalid) {
      data.input.className += ' is-invalid';
      data.input['aria-invalid'] = true;
    }

    if (this.props.error && data.meta.touched && !data.meta.error) {
      data.input.className += ' is-valid';
    }

    return (
      <div className={`form-group used-procedure-title`}>
        {data.input.name === 'procedureName' ? (
          <label
            htmlFor={`usedprocedure_${data.input.name}`}
            className="form-control-label w-100 editing-using-procedure-label"
          >
            {/* Using Procedure */}
            Using Template
          </label>
        ) : (
          ''
        )}
        <div
          //{...data.input}
          //type={data.type}
          //step={data.step}
          //required={data.required}
          //placeholder={data.placeholder}
          id={`usedprocedure_${data.input.name}`}
          style={data.style}
          //readOnly={true}
        >
          {data.input.value}
        </div>
        {isInvalid && <div className="invalid-feedback">{data.meta.error}</div>}
      </div>
    );
  };

  renderNoteField = data => {
    data.input.className = 'form-control';

    const isInvalid = data.meta.touched && !!data.meta.error;
    if (isInvalid) {
      data.input.className += ' is-invalid';
      data.input['aria-invalid'] = true;
    }

    if (this.props.error && data.meta.touched && !data.meta.error) {
      data.input.className += ' is-valid';
    }

    return (
      <div className={`form-group`}>
        <ReactQuill
          {...data.input}
          required={data.required}
          placeholder={data.placeholder}
          id={`usedprocedure_${data.input.name}`}
          style={data.style}
          onKeyUp={event => {
            this.handleNoteChange(event, data);
            //data.input.onKeyUp(event);
          }}
          onClick={event => this.handleClick(event, data)}
          onBlur={() => this.handleBlur()}
          autoFocus={data.autoFocus || false}
          readOnly={data.readOnly || false}
          modules={{
            toolbar: [
              ['bold', 'italic', 'underline'],
              ['link'],
              [{ color: [] }]
            ]
          }}
          formats={['bold', 'italic', 'underline', 'link', 'color']}
          ref={ref => this.attachNoteField(ref, data.input.name)}
        />
        {isInvalid && <div className="invalid-feedback">{data.meta.error}</div>}
      </div>
    );
  };

  renderDescriptionField = data => {
    return (
      data.input.value && (
        <div className={'used-procedure-description-wrapper'}>
          <div className={`used-procedure-description text-muted`}>
            <Interweave content={data.input.value} />
          </div>
        </div>
      )
    );
  };

  renderStepFileField = data => {
    return (
      data.input.value &&
      Object.keys(data.input.value).length > 0 && (
        <div className={'used-procedure-step-files-wrapper'}>
          <div className={`used-procedure-step-files`}>
            {Object.keys(data.input.value).map(fileId => {
              let file = data.input.value[fileId];

              return (
                <span className={'used-procedure-step-file'}>
                  {file.filetype.indexOf('image/') !== -1 ? (
                    <SecureImage
                      file={file}
                      zoom={this.zoom}
                      id={'used-procedure-step-file-' + fileId}
                      attributes={{
                        width: 100
                      }}
                    />
                  ) : (
                    <SecureFile
                      file={file}
                      id={'used-procedure-step-file-' + fileId}
                      attributes={{
                        className: 'btn btn-primary ml-1 mr-1',
                        style: { cursor: 'pointer' }
                      }}
                    />
                  )}
                </span>
              );
            })}
          </div>
        </div>
      )
    );
  };

  renderSelectField = data => {
    return (
      <div className={`form-group usedprocedure-${data.input.name}-wrapper`}>
        <label
          htmlFor={`usedprocedure_${data.input.name}`}
          className="form-control-label"
        >
          {data.label}
        </label>
        <AsyncCreatableSelect
          {...data.input}
          step={data.step}
          required={data.required}
          placeholder={data.placeholder}
          id={`usedprocedure_${data.input.name}`}
          className={`usedprocedure-${data.input.name}`}
          classNamePrefix={`usedprocedure-${data.input.name}`}
          defaultOptions
          onBlur={event => {
            event.preventDefault(); // https://stackoverflow.com/questions/48419984/how-to-have-searchable-dropdown-in-redux-form
            this.handleBlur();
          }}
          onChange={(newValue, actionMeta) => {
            this.handleTagChange(newValue, actionMeta);
            data.input.onChange(newValue, actionMeta);
          }}
          isMulti={true}
          isClearable={false}
          loadOptions={data.loadOptions}
          // setting the value explicitly so that it will appear on page load
          value={
            data.input.value === ''
              ? undefined
              : //: [{ label: data.input.value, value: data.input.value }]
                data.input.value
          }
          getOptionLabel={this.getOptionLabel}
          getOptionValue={this.getOptionValue}
          aria-describedby={`usedprocedure_${data.input.name}_help`}
        />
        <small
          id={`usedprocedure_${data.input.name}_help`}
          className={'form-text text-muted'}
        >
          {data.help}
        </small>
      </div>
    );
  };

  renderCheckboxField = data => {
    let nameElement = document.getElementById(
      'usedprocedure_' + data.input.name.replace('isCompleted', 'name')
    );
    if (nameElement) {
      nameElement.className = nameElement.className.replace(
        'is-completed-step',
        ''
      );
      if (data.input.checked || data.input.value) {
        // is currently checked
        if (nameElement.className.indexOf('is-completed-step') === -1) {
          nameElement.className += ' is-completed-step';
        }
      } else {
        // is currently not checked
      }
    }

    return (
      <label className={'large-check mb-0'}>
        <input
          {...data.input}
          checked={data.input.checked || data.input.value}
          type={'checkbox'}
          step={data.step}
          id={`usedprocedure_${data.input.name}`}
          onClick={event => this.handleCompletedCheckboxFieldClick(event, data)}
          //disabled={!this.state.hasFirstTag}
        />
        {/*
        {!this.state.hasFirstTag ? (
          <button
            type="button"
            id="use-procedure-checkmark-tooltip"
            className={'pl-0 pr-0'}
            onClick={event => {
              let elems = document.querySelectorAll('.visible-tooltip');
              if (elems.length > 0) {
                Object.keys(elems).map(index => {
                  elems[index].classList.remove('visible-tooltip');
                  return index;
                });
              }

              event.target.parentNode.classList += ' visible-tooltip';
            }}
          >
            <p id={'checkmark-inner-tooltip'} className={'mb-0'}>
              <i className="fa fa-exclamation-triangle"></i> Please add at least
              one tag to start using this procedure
            </p>
            <span
              className={
                'check' +
                (data.input.checked || data.input.value ? ' fa fa-check' : '')
              }
              id={`usedprocedure_${data.input.name}_check`}
            >
              {''}
            </span>
          </button>
        ) : (
          <span
            className={
              'check' +
              (data.input.checked || data.input.value ? ' fa fa-check' : '')
            }
            id={`usedprocedure_${data.input.name}_check`}
          >
            {''}
          </span>
        )}
        */}
        {/* a Tag is no longer required */}
        <span
          className={
            'check' +
            (data.input.checked || data.input.value ? ' fa fa-check' : '')
          }
          id={`usedprocedure_${data.input.name}_check`}
        >
          {''}
        </span>
      </label>
    );
  };

  handleCompletedCheckboxFieldClick = (event, data) => {
    this.handleClick(event, data);

    let nameElement = document.getElementById(
      'usedprocedure_' + data.input.name.replace('isCompleted', 'name')
    );
    if (nameElement) {
      nameElement.className = nameElement.className.replace(
        'is-completed-step',
        ''
      );
      if (data.input.checked || data.input.value) {
        // is currently checked, transitions to: will not be checked
      } else {
        // is currently not checked, transitions to: will be checked
        if (nameElement.className.indexOf('is-completed-step') === -1) {
          nameElement.className += ' is-completed-step';
        }
      }
    }
  };

  renderNotApplicableCheckboxField = data => {
    let nameElement = document.getElementById(
      'usedprocedure_' + data.input.name.replace('isNotApplicable', 'name')
    );
    if (nameElement) {
      nameElement.className = nameElement.className.replace(
        'is-not-applicable',
        ''
      );
      if (data.input.checked || data.input.value) {
        // is currently checked
        if (nameElement.className.indexOf('is-not-applicable') === -1) {
          nameElement.className += ' is-not-applicable';
        }
      } else {
        // is currently not checked
      }
    }

    return (
      <label className={'used-procedure-step-not-applicable'}>
        <input
          {...data.input}
          checked={data.input.checked || data.input.value}
          type={'checkbox'}
          step={data.step}
          id={`usedprocedure_${data.input.name}`}
          onClick={event => this.handleNotApplicableFieldClick(event, data)}
          //disabled={!this.state.hasFirstTag}
        />
        {/*
        <span
          className={
            'badge' +
            (data.input.checked || data.input.value
              ? ' badge-danger'
              : ' badge-light')
          }
          id={`usedprocedure_${data.input.name}_check`}
          data-title={
            'Please add at least one tag to start using this procedure'
          }
          title={
            !this.state.hasFirstTag
              ? 'Please add at least one tag to start using this procedure'
              : undefined
          }
        >
          N/A
        </span>
        */}
        {/* a Tag is no longer required */}
        <span
          className={
            'badge' +
            (data.input.checked || data.input.value
              ? ' badge-danger'
              : ' badge-light')
          }
          id={`usedprocedure_${data.input.name}_check`}
        >
          N/A
        </span>
      </label>
    );
  };

  handleNotApplicableFieldClick = (event, data) => {
    this.handleClick(event, data);

    let nameElement = document.getElementById(
      'usedprocedure_' + data.input.name.replace('isNotApplicable', 'name')
    );
    if (nameElement) {
      nameElement.className = nameElement.className.replace(
        'is-not-applicable',
        ''
      );
      if (data.input.checked || data.input.value) {
        // is currently checked, transitions to: will not be checked
      } else {
        // is currently not checked, transitions to: will be checked
        if (nameElement.className.indexOf('is-not-applicable') === -1) {
          nameElement.className += ' is-not-applicable';
        }
      }
    }
  };

  renderLastSavedText = data => {
    let time = data.input.value;

    if (time) {
      return (
        <span className={'last-saved-wrapper'}>
          <span className={'last-saved-text'}>Last Saved at:&nbsp;</span>
          <span className={'last-saved-time'}>{time}</span>
        </span>
      );
    }

    return 'Save';
  };

  attachNode = node => {
    this._button = node;
  };

  getButtonComponent = () => {
    return this._button;
  };

  handleClick = (event, data) => {
    setTimeout(() => {
      this.getButtonComponent().click();
    }, 500);
  };

  handleBlur = () => {
    this.getButtonComponent().click();
  };

  handleTagChange = (newValue, actionMeta) => {
    if (
      ((Array.isArray(newValue) && newValue.length > 0) || !!newValue) &&
      (actionMeta.action === 'create-option' ||
        actionMeta.action === 'set-value' ||
        actionMeta.action === 'select-option')
    ) {
      this.setState(
        {
          hasFirstTag: true
        },
        () => {
          /* // a Tag is no longer required, @see hasFirstTag
          // as the state is updated, remove the disabled attribute from checkbox fields, otherwise the field cannot be editable
          let editElems = document.querySelectorAll(
            'input[type="checkbox"][name*="isCompleted"], input[type="checkbox"][name*="isNotApplicable"]'
          );
          if (editElems.length > 0) {
            for (let i = 0; i < editElems.length; ++i) {
              editElems[i].removeAttribute('disabled');
              let checkElem = document.getElementById(
                editElems[i].id + '_check'
              );
              checkElem.removeAttribute('title');
            }
          }
          */

          this.handleBlur();
        }
      );
    }

    if (
      ((Array.isArray(newValue) && newValue.length <= 0) || !newValue) &&
      (actionMeta.action === 'deselect-option' ||
        actionMeta.action === 'remove-value' ||
        actionMeta.action === 'pop-value' ||
        actionMeta.action === 'clear')
    ) {
      this.setState(
        {
          hasFirstTag: false
        },
        () => {
          /* // a Tag is no longer required, @see hasFirstTag
          // as the state is updated, add the disabled attribute to checkbox fields, so that the field cannot be edited
          let editElems = document.querySelectorAll(
            'input[type="checkbox"][name*="isCompleted"], input[type="checkbox"][name*="isNotApplicable"]'
          );
          if (editElems.length > 0) {
            for (let i = 0; i < editElems.length; ++i) {
              editElems[i].setAttribute('disabled', true);
              let checkElem = document.getElementById(
                editElems[i].id + '_check'
              );
              checkElem.setAttribute(
                'title',
                checkElem.getAttribute('data-title')
              );
            }
          }
          */

          this.handleBlur();
        }
      );
    }
  };

  handleNoteChange = (event, data) => {
    let editor = this.getNoteFieldComponent(data.input.name);
    if (!editor) {
      return;
    }

    let elem = event.target;
    // ql-editor / ql-container / quill / form-group / used-procedure-info-note / used-procedure-info->wrapper
    let wrapper = elem.parentNode.parentNode.parentNode.parentNode.parentNode;
    let toggle = wrapper.querySelector('.used-procedure-info-toggle-note');

    if (!!toggle) {
      toggle.classList.remove('fa-sticky-note-o');
      toggle.classList.remove('fa-sticky-note');
      toggle.classList.remove('with-note');

      let value = editor.getEditor().getText();

      if (!!value && !elem.classList.contains('ql-blank')) {
        toggle.className += ' fa-sticky-note with-note';
      } else {
        toggle.className += ' fa-sticky-note-o';
      }
    }
  };

  preventFormSubmit = event => {
    return this.getTreeComponent().preventFormSubmit(event);
  };

  setHasFirstItem = (status = true) => {
    //this.setState({ hasFirstItem: status });
  };

  attachTree = node => {
    this._usedProcedureStepTree = node;
  };

  getTreeComponent = () => {
    return this._usedProcedureStepTree;
  };

  pressFormSubmit = () => {
    if (typeof this.props.pressFormSubmit === 'function') {
      this.props.pressFormSubmit();
    }
  };

  getEditItemActionComponent = (data, index, clickHandler, fieldName) => {
    // the input index is the usedProcedureStep ID
    let usedProcedureStepIndex = index;

    /*
    // the input index is the procedureStep ID
    let usedProcedureStepIndex = index;

    let mapProcedureStep = {};
    let usedProcedure = this.props.initialValues;
    if (usedProcedure && usedProcedure.usedProcedureSteps) {
      Object.keys(usedProcedure.usedProcedureSteps).map(key => {
        let usedProcedureStep = usedProcedure.usedProcedureSteps[key];
        if (
          usedProcedureStep &&
          usedProcedureStep.procedureStep &&
          usedProcedureStep.procedureStep['@id']
        ) {
          let newKey = usedProcedureStep.procedureStep['@id'].replace(
            /[^\d]* /,
            ''
          );
          mapProcedureStep[newKey] = key;
        }
        return key;
      });
    }
    // use the proper index value
    if (mapProcedureStep[index]) {
      usedProcedureStepIndex = mapProcedureStep[index];
    }
    */

    return (
      <span className="use-tree-item pull-right">
        <div className={'completed-stats'}>
          <div className={'stat-completed-by-name'}>
            <Field
              component={this.renderField}
              name={
                'usedProcedureSteps[' +
                usedProcedureStepIndex +
                '][completedByName]'
              }
            />
          </div>
          <div className={'stat-completed-at-date'}>
            <Field
              component={this.renderField}
              name={
                'usedProcedureSteps[' +
                usedProcedureStepIndex +
                '][completedAtDate]'
              }
            />
          </div>
        </div>
        <div className={'used-procedure-step-not-applicable-wrapper'}>
          <Field
            component={this.renderNotApplicableCheckboxField}
            name={
              'usedProcedureSteps[' +
              usedProcedureStepIndex +
              '][isNotApplicable]'
            }
          />
        </div>
        <Field
          component={this.renderCheckboxField}
          name={
            'usedProcedureSteps[' + usedProcedureStepIndex + '][isCompleted]'
          }
        />
      </span>
    );
  };

  getDeleteItemActionComponent = (data, index, clickHandler, fieldName) => {
    return '';
  };

  getSelectItemActionComponent = (data, index, clickHandler, fieldName) => {
    return '';
  };

  getAfterItemComponent = (data, index, fieldName) => {
    let hasNote = !!data.note;
    let hasDescription = !!data.description;
    let hasFiles =
      !!data.usedProcedureStepFiles &&
      Object.keys(data.usedProcedureStepFiles).length > 0;
    let noteFieldName = 'usedProcedureSteps[' + index + '][note]';

    return (
      <Accordion
        className={
          'used-procedure-info-wrapper right-side' +
          (hasNote ? ' with-note' : '') +
          (hasDescription ? ' with-description' : '') +
          (hasFiles ? ' with-files' : '')
        }
      >
        <span className={'used-procedure-info-actions'}>
          <OverlayTrigger
            placement={'top'}
            overlay={<Tooltip id={'tooltip-note'}>Note</Tooltip>}
          >
            <Accordion.Toggle
              eventKey={'note'}
              as={'span'}
              className={
                'used-procedure-info-toggle-note' +
                (hasNote
                  ? ' fa fa-sticky-note with-note'
                  : ' fa fa-sticky-note-o')
              }
              title={'Note'}
              onClick={event => {
                let elem = event.target.parentNode.parentNode;
                if (elem.classList.contains('isOpen')) {
                  elem.classList.remove('isOpen');
                } else {
                  elem.className += ' isOpen';

                  setTimeout(() => {
                    this.getNoteFieldComponent(noteFieldName).focus();
                  }, 100);
                }
              }}
            >
              <span className={'sr-only'}>Note</span>
            </Accordion.Toggle>
          </OverlayTrigger>
          {hasDescription && (
            <OverlayTrigger
              placement={'top'}
              overlay={
                <Tooltip id={'tooltip-description'}>Description</Tooltip>
              }
            >
              <Accordion.Toggle
                eventKey={'description'}
                as={'span'}
                className={
                  'used-procedure-info-toggle-description fa fa-info-circle'
                }
                title={'Description'}
              >
                <span className={'sr-only'}>Description</span>
              </Accordion.Toggle>
            </OverlayTrigger>
          )}
          {hasFiles && (
            <OverlayTrigger
              placement={'top'}
              overlay={<Tooltip id={'tooltip-attachments'}>Files</Tooltip>}
            >
              <Accordion.Toggle
                eventKey={'files'}
                as={'span'}
                className={'used-procedure-info-toggle-files fa fa-paperclip'}
                title={'Files'}
              >
                <span className={'sr-only'}>Files</span>
              </Accordion.Toggle>
            </OverlayTrigger>
          )}
        </span>
        <Accordion.Collapse
          eventKey={'note'}
          className={'used-procedure-info-note'}
        >
          <>
            <Field component={this.renderNoteField} name={noteFieldName} />
            <button
              className={'btn btn-primary pull-right'}
              onClick={event => {
                let elem = event.target.parentNode.parentNode;
                let toggle = elem.querySelector(
                  '.used-procedure-info-toggle-note'
                );

                if (!!toggle) {
                  toggle.click();
                }
              }}
            >
              Save
            </button>
          </>
        </Accordion.Collapse>
        {hasDescription && (
          <Accordion.Collapse
            eventKey={'description'}
            className={'used-procedure-info-description'}
          >
            <Field
              component={this.renderDescriptionField}
              name={'usedProcedureSteps[' + index + '][description]'}
            />
          </Accordion.Collapse>
        )}
        {hasFiles && (
          <Accordion.Collapse
            eventKey={'files'}
            className={'used-procedure-info-files'}
          >
            <Field
              component={this.renderStepFileField}
              name={'usedProcedureSteps[' + index + '][usedProcedureStepFiles]'}
            />
          </Accordion.Collapse>
        )}
      </Accordion>
    );
  };

  _noteField = [];

  attachNoteField = (node, index) => {
    this._noteField[index] = node;
  };

  getNoteFieldComponent = index => {
    return this._noteField[index];
  };

  getTagOptions = inputValue => {
    return new Promise(dispatch => {
      return dispatch(
        fetch(inputValue ? '/tags?name=' + inputValue : '/tags')
          .then(response => response.json())
          .then(retrieved => {
            let tagOptions = [];

            retrieved['hydra:member'].map(item => {
              tagOptions.push({
                label: item['name'],
                // sending just the names in the form, the API backend will check if it exists
                //value: item['@id']
                value: item['name']
              });
              return item;
            });

            return tagOptions;
          })
          .catch(e => {
            // do nothing
          })
      );
    });
  };

  getOptionLabel = option => {
    // seems like nested object values due to renderSelectField
    if (
      option.label &&
      Array.isArray(option.label) &&
      option.label[0] &&
      option.label[0].label
    ) {
      return option.label[0].label;
    }
    if (option.label && option.label.label) {
      return option.label.label;
    }

    return option.label;
  };

  getOptionValue = option => {
    // seems like nested object values due to renderSelectField
    if (
      option.value &&
      Array.isArray(option.value) &&
      option.value[0] &&
      option.value[0].value
    ) {
      return option.value[0].value;
    }
    if (option.value && option.value.value) {
      return option.value.value;
    }

    return option.value;
  };

  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit}
        onKeyDown={this.preventFormSubmit}
      >
        <div class={'used-procedure-actions-wrapper'}>
          <button
            type="submit"
            id={'new-submit-button'}
            className="btn"
            ref={this.attachNode.bind(this)}
          >
            Save
          </button>
          <button
            onClick={this.pressFormSubmit}
            type="submit"
            className="btn btn-primary used-procedure-submit"
            disabled={this.props.submitting || this.props.pristine}
          >
            {this.props.submitting ? (
              'Saving...'
            ) : this.props.pristine ? (
              // Stored
              <Field
                component={this.renderLastSavedText}
                name="updatedAtDateTime"
                type="text"
                readOnly={true}
              />
            ) : (
              // Save
              <Field
                component={this.renderLastSavedText}
                name="updatedAtDateTime"
                type="text"
                readOnly={true}
              />
            )}
          </button>
          <span className={'divider-vertical'}></span>
          <button
            onClick={() => window.print()}
            className="btn btn-info btn-yellow"
          >
            <span class={'fa fa-print'}></span>
            Print
          </button>
          {this.props.exportPDFButton}
        </div>

        <Field
          component={this.renderTitleField}
          name="procedureName"
          type="text"
          placeholder=""
          style={{
            border: 0,
            width: '95%'
          }}
        />

        <Field
          component={this.renderSelectField}
          name="tagNames"
          type="select"
          label="Make this easier to find later."
          help="e.g. client name, ID number, location, division, etc."
          placeholder="Add a Tag"
          loadOptions={this.getTagOptions}
        />

        <CurrentUserCan
          perform={'usedprocedure:delete'}
          yes={() => (
            <Field
              component={this.renderDateField}
              name="dueAtDate"
              type="date"
              label="Due Date"
              placeholder=""
            />
          )}
          no={() => (
            <Field
              component={this.renderDateField}
              name="dueAtDate"
              type="date"
              label="Due Date"
              placeholder=""
              readOnly={true}
            />
          )}
        />

        <UsedProcedureStepTreeFormFields
          initialValues={this.props.initialValues}
          change={this.props.change}
          dispatch={this.props.dispatch}
          getButtonComponent={this.getButtonComponent}
          hasFirstItem={/*this.state.hasFirstItem*/ true}
          setHasFirstItem={this.setHasFirstItem}
          getEditItemActionComponent={this.getEditItemActionComponent}
          getDeleteItemActionComponent={this.getDeleteItemActionComponent}
          getSelectItemActionComponent={this.getSelectItemActionComponent}
          getAfterItemComponent={this.getAfterItemComponent}
          ref={this.attachTree.bind(this)}
        />
      </form>
    );
  }
}

// remove all .visible-tooltip class references when the user clicks away from the existing checkbox fields
// @see renderCheckboxField()
document.querySelector('body').addEventListener('click', function (ev) {
  let elems = document.querySelectorAll('.visible-tooltip');
  if (elems.length > 0) {
    Object.keys(elems).map(index => {
      elems[index].classList.remove('visible-tooltip');
      return index;
    });
  }
});

export default reduxForm({
  form: 'usedprocedure',
  /* setting updateUnregisteredFields: true should allow the updatedAtDateTime value to update the form data */
  /* WARNING: it actually breaks the intended functionality, so DO NOT enable this */
  /* @see src/reducers/usedprocedure/update.js updated() for workaround */
  //updateUnregisteredFields: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(Form);
