import { getCurrentOrganizationForAccount } from './subscription';

export function canCreatePurchasableProcedures(account) {
  let canCreatePurchasableProcedures = false;
  const currentOrganization = getCurrentOrganizationForAccount(account);

  if (
    !!currentOrganization &&
    !!currentOrganization.canCreatePurchasableProcedures
  ) {
    canCreatePurchasableProcedures = true;
  }

  return canCreatePurchasableProcedures;
}
