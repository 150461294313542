import { isEqual } from 'lodash';
import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Field } from 'redux-form';

import imageUpload from '../../assets/img/temp-upload-image.png';
import { fetch } from '../../utils/dataAccess';
import SecureImage from '../SecureImage';
import FileAttachment from './FileAttachment';

export class PurchasableProcedureForm extends Component {
  state = {
    purchasableProcedureId:
      (this.props.initialValues && this.props.initialValues['@id']) || null,
    purchasableProcedureCardContainer: '',
    isFileModalOpen: false,
    isDeleteModalOpen: false,
    purchasableProcedureFile:
      (!!this.props.initialValues &&
        this.props.initialValues.purchasableProcedureFile) ||
      false,
    fileAttachmentValues: this.props.initialValues,
    uppy: null
  };

  componentDidMount() {
    if (typeof this.props.changeFormField === 'function') {
      this.props.changeFormField(
        'procedure',
        'purchasableProcedure.@id',
        this.getIdValue()
      );
    }

    const purchasableProcedureCardContainer = document.getElementById(
      'purchasable-procedure-container-id'
    );

    window.addEventListener('scroll', this.handleScroll);

    this.setState(prevState => {
      return {
        purchasableProcedureCardContainer: purchasableProcedureCardContainer
      };
    });
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (
      (this.props.initialValues &&
        this.props.initialValues['@id'] &&
        this.props.initialValues['@id'] !==
          this.state.purchasableProcedureId) ||
      (this.props.initialValues &&
        this.props.initialValues.purchasableProcedureFile &&
        this.props.initialValues.purchasableProcedureFile !==
          this.state.purchasableProcedureFile)
    ) {
      return true;
    }

    return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    let container = this.state.purchasableProcedureCardContainer;

    if (!container) {
      return;
    }
    if (window.scrollY >= 240) {
      if (
        container.classList.contains('purchasable-procedure-hidden') === false
      ) {
        container.classList.add('purchasable-procedure-hidden');
      }
    } else {
      if (
        container.classList.contains('purchasable-procedure-hidden') === true
      ) {
        container.classList.remove('purchasable-procedure-hidden');
      }
    }
  };

  // TODO Handle onScroll event to hide pricing card
  // TODO Perhaps an accordion arrow tied to the same toggle feature as the legend?

  renderInput = data => {
    return (
      <input
        {...data.input}
        type={data.type}
        step={data.step}
        required={data.required}
        placeholder={data.placeholder}
        id={`purchasable_procedure_${data.input.name}`}
        style={data.style}
        onClick={data.onClick || undefined}
        autoFocus={data.autoFocus || false}
        readOnly={data.readOnly || false}
        value={data.input.value || ''}
      />
    );
  };

  renderSwitch = data => {
    switch (data.type) {
      case 'checkbox':
        data.input.className = 'form-check-input';
        return (
          <div className={'form-check'}>
            {this.renderInput(data)}

            <label
              htmlFor={`purchasable_procedure_${data.input.name}`}
              className={'form-check-label'}
            >
              {data.labelText}
            </label>
          </div>
        );
      case 'numeric':
        return (
          <>
            <label htmlFor={`purchasable_procedure_${data.input.name}`}>
              {data.labelText}
            </label>
            <div className="left-inner-addon">
              <span>$</span>
              {this.renderInput(data)}
            </div>
          </>
        );
      default:
        return <>{this.renderInput(data)}</>;
    }
  };

  renderField = data => {
    data.input.className = 'form-control';

    const isInvalid = data.meta.touched && !!data.meta.error;
    if (isInvalid) {
      data.input.className += ' is-invalid';
      data.input['aria-invalid'] = true;
    }

    if (this.props.error && data.meta.touched && !data.meta.error) {
      data.input.className += ' is-valid';
    }

    return (
      <>
        {this.renderSwitch(data)}
        {isInvalid && <div className="invalid-feedback">{data.meta.error}</div>}
      </>
    );
  };

  getIdValue = () => {
    return this.props.initialValues ? this.props.initialValues['@id'] : null;
  };

  // getFile = () => {
  //   return this.props.initialValues
  //     ? this.props.initialValues.purchasableProcedureFile
  //     : null;
  // };

  updatePurchasableProcedureId = () => {
    if (
      this.props.initialValues &&
      this.props.initialValues['@id'] &&
      this.props.initialValues['@id'] === this.state.purchasableProcedureId
    ) {
      return;
    }

    this.setState({
      purchasableProcedureId:
        (this.props.initialValues && this.props.initialValues['@id']) || null
    });

    const itemId = this.getIdValue();
    if (
      itemId &&
      itemId !== this.state.purchasableProcedureId &&
      typeof this.props.changeFormField === 'function'
    ) {
      this.props.changeFormField(
        'procedure',
        'purchasableProcedure.@id',
        itemId
      );
    }
  };

  setUppy = uppy => {
    this.setState({ uppy: uppy });
  };

  updatePurchasableProcedureFile = () => {
    if (
      this.props.initialValues &&
      this.props.initialValues === this.state.fileAttachmentValues
    ) {
      return;
    }

    this.setState({
      fileAttachmentValues: this.props.initialValues
    });
  };

  handleFileAttachmentOpenModal = () => {
    this.setState({
      isFileModalOpen: true
    });
  };

  onRequestClose = () => {
    this.setState({
      isFileModalOpen: false
    });

    let file =
      this.props.retrievedProcedure &&
      this.props.retrievedProcedure.purchasableProcedure &&
      this.props.retrievedProcedure.purchasableProcedure
        .purchasableProcedureFile;
    if (typeof this.props.changeFormField === 'function') {
      this.props.changeFormField(
        'procedure',
        'purchasableProcedure.purchasableProcedureFile',
        file
      );
    }
  };

  handleDeleteModalOpen = () => {
    this.setState({
      isDeleteModalOpen: true
    });
  };

  handleDeleteModalClose = () => {
    this.setState({
      isDeleteModalOpen: false
    });
  };

  handleFileRemoved = (file, component) => {
    if (!component.getUploaderComponent().props.uppy.getFiles().length) {
      let elem = component.getUploaderComponent().container;
      let wrapper = elem.parentNode.parentNode.parentNode.parentNode;
      let toggle = wrapper.querySelector('.procedure-attachment-wrapper');

      if (!!toggle) {
        wrapper.classList.remove('with-files');
        toggle.classList.remove('with-files');
      }
    }
  };

  handleFileDelete = () => {
    let purchasableProcedureFile =
      this.state.purchasableProcedureFile &&
      this.state.purchasableProcedureFile;
    if (!purchasableProcedureFile) {
      return;
    }

    fetch(purchasableProcedureFile.location, {
      method: 'DELETE'
    }).then(response => {
      if (typeof this.props.retrieveProcedure === 'function') {
        this.props.retrieveProcedure();
      }

      if (typeof this.props.changeFormField === 'function') {
        this.props.changeFormField(
          'procedure',
          'purchasableProcedure.purchasableProcedureFile',
          null
        );
      }
    });
    this.setState({
      purchasableProcedureFile: false,
      fileAttachmentValues: this.props.initialValues
    });

    this.state.uppy && this.state.uppy.reset();
  };

  handleFileUploadComplete = (results, component) => {
    if (!!results.successful.length) {
      let elem = component.getUploaderComponent().container;
      // div / procedure-attachment / procedure-attachment-container / procedure-attachment-wrapper / extends-tree-item
      let wrapper = elem.parentNode.parentNode.parentNode.parentNode;
      let toggle = wrapper.querySelector('.procedure-attachment-wrapper');

      if (!!toggle) {
        wrapper.classList.remove('with-files');
        toggle.classList.remove('with-files');

        wrapper.className += ' with-files';
        toggle.className += ' with-files';
      }

      results.successful.forEach(file => {
        component.updateFileSource(file.id);
        file.location = file.tus && file.tus.uploadUrl;
        file.fileType = file.type;
        this.setState({
          purchasableProcedureFile: file
        });
      });
    }

    if (typeof this.props.retrieveProcedure === 'function') {
      this.props.retrieveProcedure();
    }
  };

  render() {
    this.updatePurchasableProcedureId();
    this.updatePurchasableProcedureFile();

    return (
      <div
        className={'purchasable-procedure-container'}
        id={'purchasable-procedure-container-id'}
      >
        <Field
          component={this.renderField}
          type={'hidden'}
          name={'purchasableProcedure.@id'}
          value={this.getIdValue()}
        />
        <h5 className={'ml-3 mr-3 mt-3'}>Pricing</h5>
        <hr />
        <div className={'ml-3 mr-3 mb-3'}>
          <div className="mb-4">
            <Field
              component={this.renderField}
              type={'checkbox'}
              name={'purchasableProcedure.isPurchasable'}
              labelText={'Is Purchasable?'}
              onClick={e => {
                if (e.target.checked) {
                  this.props.getButtonComponent &&
                    this.props.getButtonComponent().click();
                }
              }}
            />
          </div>
          <div className="row mb-4">
            <div className="col">
              <Field
                component={this.renderField}
                type={'numeric'}
                name={'purchasableProcedure.price'}
                labelText={'Price'}
                placeholder={'0.00'}
              />
            </div>
            <div className="col">
              <Field
                component={this.renderField}
                type={'numeric'}
                name={'purchasableProcedure.renewalDiscountPrice'}
                labelText={'Renewal Price'}
                placeholder={'0.00'}
              />
            </div>
          </div>
          {this.state.fileAttachmentValues &&
            this.state.fileAttachmentValues['@id'] && (
              <div className={'image-upload'}>
                <div className={'mb-2'}>Upload Preview Image</div>
                <FileAttachment
                  originalData={this.state.fileAttachmentValues}
                  isModalOpen={this.state.isFileModalOpen}
                  onRequestClose={this.onRequestClose}
                  onFileRemoved={this.handleFileRemoved}
                  onComplete={this.handleFileUploadComplete}
                  setUppy={this.setUppy}
                />
                <Modal
                  show={this.state.isDeleteModalOpen || false}
                  onHide={() => this.handleDeleteModalClose()}
                  centered
                  size="xl"
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      Are you sure you want to delete this Preview Image?
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>This will delete:</p>
                    <ul>
                      <li>This Preview Image</li>
                    </ul>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      className={'btn'}
                      onClick={() => this.handleDeleteModalClose()}
                    >
                      Cancel
                    </button>
                    <button
                      className={'btn btn-danger'}
                      onClick={event => {
                        this.handleFileDelete();
                        this.handleDeleteModalClose();
                      }}
                    >
                      Delete Preview Image
                    </button>
                  </Modal.Footer>
                </Modal>
                <div className={'image-container'}>
                  {this.state.purchasableProcedureFile && (
                    <OverlayTrigger
                      placement={'top'}
                      overlay={
                        <Tooltip id={'tooltip-delete'}>
                          Delete Preview Image
                        </Tooltip>
                      }
                    >
                      <span
                        onClick={this.handleDeleteModalOpen}
                        className="fa fa-trash"
                      >
                        <span className="sr-only">Delete</span>
                      </span>
                    </OverlayTrigger>
                  )}
                  <div onClick={this.handleFileAttachmentOpenModal}>
                    {this.state.purchasableProcedureFile ? (
                      <SecureImage
                        file={this.state.purchasableProcedureFile}
                        zoom={false}
                        alt={'Upload Preview Image'}
                        src={imageUpload}
                        id={'purchasable-procedure-file'}
                        attributes={{
                          width: 125,
                          style: { cursor: 'pointer' }
                        }}
                      />
                    ) : (
                      <img
                        src={imageUpload}
                        alt={'Upload Preview Image'}
                        id={'image-upload-placeholder'}
                        width={125}
                        height={125}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default PurchasableProcedureForm;
