import './TreeFormFields.css';

import { debounce } from 'lodash';
import React, { Component } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Field } from 'redux-form';

import { handleKeyDown } from '../../utils/keyboard';
import KeyboardShortcuts from '../legend/KeyboardShortcuts';
import DragDropWithNestingTree from '../tree/DragDropWithNestingTree';
import { getStepTree, removeFieldWithinTree } from '../tree/stepTree';

class TreeFormFields extends Component {
  state = {
    isCreatingTreeField: false,
    isMovingTreeField: false,
    isSelectingTreeField: false,
    delayedDeleteItems: [],
    expansionMap: {},
    fieldEditMap: {},
    lastSelectedFieldIndex: this.props.lastSelectedFieldIndex,
    hasFirstitem: this.props.hasFirstitem
  };

  renderField = data => {
    data.input.className = 'form-control';

    const isInvalid = data.meta.touched && !!data.meta.error;
    if (isInvalid) {
      data.input.className += ' is-invalid';
      data.input['aria-invalid'] = true;
    }

    if (this.props.error && data.meta.touched && !data.meta.error) {
      data.input.className += ' is-valid';
    }

    let wrapperClass = 'form-group';
    if (
      data.originalIndex &&
      this.state.lastSelectedFieldIndex === data.originalIndex
    ) {
      wrapperClass += ' selected-item';
      // add styling class to parent element
      setTimeout(() => {
        // input / form-group / procedure-step / tree-item-field / draggable
        let selectedParents = document.getElementsByClassName(
          'selected-item-parent'
        );
        Object.keys(selectedParents).map(spIndex => {
          if (!!selectedParents[spIndex]) {
            selectedParents[spIndex].className = selectedParents[
              spIndex
            ].className.replace('selected-item-parent', '');
          }
          return spIndex;
        });
        let selectedEls = document.getElementsByName(data.input.name);
        if (selectedEls.length > 0 && selectedEls[0]) {
          let selectedEl = selectedEls[0];
          let parentEl = selectedEl.parentNode.parentNode.parentNode.parentNode;
          if (parentEl.className.indexOf('selected-item-parent') === -1) {
            parentEl.className += ' selected-item-parent';
          }
        }
      }, 100);
    }

    return (
      <div className={wrapperClass}>
        {/*<div className={`form-group`}>*/}
        {/*
        <label
          htmlFor={`${this.props.fieldIdPrefix}_${data.input.name}`}
          className="form-control-label"
        >
          {data.input.name}
        </label>
        */}
        <input
          {...data.input}
          type={data.type}
          step={data.step}
          required={data.required}
          placeholder={data.placeholder}
          id={`${this.props.fieldIdPrefix}_${data.input.name}`}
          style={data.style}
          onDragOver={event => this.handleDragOver(event, data)}
          onDrop={event => this.handleDrop(event, data)}
          onClick={event => this.handleClick(event, data)}
          onBlur={event => this.handleBlur(event, data)}
          onFocus={event => this.handleFocus(event, data)}
          onKeyDown={event =>
            handleKeyDown(
              event,
              data,
              this,
              this.props.fieldName,
              this.props.addElementId
            )
          }
          autoFocus={data.autoFocus || false}
          readOnly={data.readOnly || false}
          title={
            !!this.props.displayTitleInField ? data.input.value : undefined
          }
        />
        {isInvalid && <div className="invalid-feedback">{data.meta.error}</div>}
      </div>
    );
  };

  renderFieldContainer = data => {
    data.input.className = 'form-control tree-field-container';

    const isInvalid = data.meta.touched && !!data.meta.error;
    if (isInvalid) {
      data.input.className += ' is-invalid';
      data.input['aria-invalid'] = true;
    }

    if (this.props.error && data.meta.touched && !data.meta.error) {
      data.input.className += ' is-valid';
    }

    let wrapperClass = 'form-group';
    if (
      data.originalIndex &&
      this.state.lastSelectedFieldIndex === data.originalIndex
    ) {
      wrapperClass += ' selected-item';
      // add styling class to parent element
      setTimeout(() => {
        // input / form-group / procedure-step / tree-item-field / draggable
        let selectedParents = document.getElementsByClassName(
          'selected-item-parent'
        );
        Object.keys(selectedParents).map(spIndex => {
          if (!!selectedParents[spIndex]) {
            selectedParents[spIndex].className = selectedParents[
              spIndex
            ].className.replace('selected-item-parent', '');
          }
          return spIndex;
        });
        let selectedEls = document.getElementsByName(data.input.name);
        if (selectedEls.length > 0 && selectedEls[0]) {
          let selectedEl = selectedEls[0];
          let parentEl = selectedEl.parentNode.parentNode.parentNode.parentNode;
          if (parentEl.className.indexOf('selected-item-parent') === -1) {
            parentEl.className += ' selected-item-parent';
          }
        }
      }, 100);
    }

    if (data.originalData && !!data.originalData.isNotApplicable) {
      data.input.className += ' is-not-applicable';
    }

    if (data.originalData && !!data.originalData.isCompleted) {
      data.input.className += ' is-completed-step';
    }

    return (
      <div className={wrapperClass}>
        {/*<div className={`form-group`}>*/}
        {/*
        <label
          htmlFor={`${this.props.fieldIdPrefix}_${data.input.name}`}
          className="form-control-label"
        >
          {data.input.name}
        </label>
        */}
        <div
          {...data.input}
          name={undefined}
          value={undefined}
          // type={data.type}
          // step={data.step}
          // required={data.required}
          // placeholder={data.placeholder}
          id={`${this.props.fieldIdPrefix}_${data.input.name}`}
          style={{ display: 'inline-table', ...data.style }}
          onDragOver={event => this.handleDragOver(event, data)}
          onDrop={event => this.handleDrop(event, data)}
          onClick={event => this.handleFieldContainerClick(event, data)}
          onBlur={event => this.handleBlur(event, data)}
          onFocus={event => this.handleFieldContainerFocus(event, data)}
          // onKeyDown={event =>
          //   handleKeyDown(
          //     event,
          //     data,
          //     this,
          //     this.props.fieldName,
          //     this.props.addElementId
          //   )
          // }
          // autoFocus={data.autoFocus || false}
          // readOnly={data.readOnly || false}
          title={
            !!this.props.displayTitleInField ? data.input.value : undefined
          }
        >
          {data.input.value}
        </div>
        {isInvalid && <div className="invalid-feedback">{data.meta.error}</div>}
      </div>
    );
  };

  renderHiddenField = data => {
    return (
      <input
        {...data.input}
        type={'hidden'}
        step={data.step}
        id={`${this.props.fieldIdPrefix}_${data.input.name}`}
      />
    );
  };

  getButtonComponent = () => {
    if (typeof this.props.getButtonComponent === 'function') {
      return this.props.getButtonComponent();
    }
  };

  handleDragOver = (event, data) => {
    if (data && data.originalData && data.originalIndex) {
      this.dragOverTreeItem(data.originalData, data.originalIndex, event);
    }
  };

  handleDrop = (event, data) => {
    if (data && data.originalData && data.originalIndex) {
      this.dropTreeItem(data.originalData, data.originalIndex, event);
    }
  };

  handleClick = (event, data) => {
    if (data && data.originalData && data.originalIndex) {
      let isReadOnly = this.getEditItemReadOnlyStatus(
        data.originalData,
        data.originalIndex
      );
      if (isReadOnly) {
        this.selectTreeItem(data.originalData, data.originalIndex);
      }
    }
  };

  handleFieldContainerClick = (event, data) => {
    this.handleClick(event, data);
    this.handleFieldContainerFocus(event, data);
  };

  handleBlur = (event, data) => {
    /*
    // An empty input item should no longer be deleted automatically
    if (data && data.input && data.input.name) {
      // if an item input has an empty value, delete it
      let inputName = data.input.name;
      let inputElems = document.getElementsByName(inputName);
      if (inputElems.length > 0 && inputElems[0] && !inputElems[0].value) {
        // input / form-group / procedure-step / tree-item-field / draggable
        let element = event.target.parentNode.parentNode.parentNode.parentNode;
        let currentId = element.dataset.itemId;
        setTimeout(() => {
          this.delTreeItem(data, data.originalIndex, false, currentId);
        }, 100);
      }
    }
    */

    if (this.state.isCreatingTreeField) {
      setTimeout(() => {
        this.setIsCreatingTreeField(false);
      }, 3000);
      return;
    }
    if (this.state.isMovingTreeField) {
      setTimeout(() => {
        this.setIsMovingTreeField(false);
      }, 3000);
      return;
    }
    if (this.state.isSelectingTreeField) {
      setTimeout(() => {
        this.setIsSelectingTreeField(false);
      }, 3000);
      return;
    }

    let parent = event.target.parentNode;
    if (parent) {
      parent.classList.remove('isFocused');
    }

    if (
      typeof this.props.allowAutoSave !== 'undefined' &&
      !this.props.allowAutoSave
    ) {
      return;
    }

    this.getButtonComponent().click();
  };

  handleFocus = (event, data) => {
    let parent = event.target.parentNode;
    if (parent) {
      parent.className += ' isFocused';
    }
  };

  handleFieldContainerFocus = (event, data) => {
    let editElems = document.querySelectorAll('.isFocused');
    if (editElems.length > 0) {
      for (let i = 0; i < editElems.length; ++i) {
        editElems[i].classList.remove('isFocused');
      }
    }
    let parent = event.target.parentNode.parentNode;
    if (parent) {
      parent.className += ' isFocused';
    }
  };

  preventFormSubmit = event => {
    let allowEnterKey = true;

    if (
      typeof this.props.allowEnterKey !== 'undefined' &&
      !this.props.allowEnterKey
    ) {
      allowEnterKey = false;
    }

    if (allowEnterKey && event.target.tagName !== 'INPUT') {
      allowEnterKey = false;
    }

    if (allowEnterKey && (event.keyCode === 13 || event.key === 'Enter')) {
      // enter key
      event.preventDefault();

      let parent = event.target.parentNode;
      if (parent) {
        parent.classList.remove('isFocused');
      }

      if (
        typeof this.state.hasFirstItem !== 'undefined' &&
        !this.state.hasFirstitem
      ) {
        this.addTreeItem();
      }

      return false;
    }
  };

  setHasFirstItem = (status = true) => {
    this.setState({ hasFirstitem: status });

    if (typeof this.props.setHasFirstItem === 'function') {
      this.props.setHasFirstItem(status);
    }
  };

  setIsCreatingTreeField = (status = true) => {
    this.setState({ isCreatingTreeField: status });
  };

  setIsMovingTreeField = (status = true) => {
    this.setState({ isMovingTreeField: status });
  };

  setIsSelectingTreeField = (status = true) => {
    this.setState({ isSelectingTreeField: status });
  };

  delTreeItem = (data, index, confirm = true, currentId = '', event = null) => {
    // if an item input has an empty value, no need to confirm the deletion
    let inputName = this.props.fieldName + '[' + index + '][name]';
    let inputElems = document.getElementsByName(inputName);
    if (inputElems.length > 0 && inputElems[0] && !inputElems[0].value) {
      confirm = false;
    }

    if (this.props.disableDeleteDefaultConfirmation) {
      confirm = false;

      if (!currentId && event) {
        currentId =
          inputElems[0].parentNode.parentNode.parentNode.parentNode.getAttribute(
            'data-item-id'
          );
      }
    }

    if (confirm) {
      let itemIsParent =
        inputElems[0].parentNode.parentNode.parentNode.parentNode.getAttribute(
          'data-is-parent'
        );

      if (
        itemIsParent === 'true' &&
        this.props.deleteMultipleItemConfirmMessage
      ) {
        if (!window.confirm(this.props.deleteMultipleItemConfirmMessage)) {
          return;
        }
      } else {
        if (!window.confirm(this.props.deleteItemConfirmMessage)) {
          return;
        }
      }
    }

    if (!currentId && event) {
      // input / form-group / procedure-step / tree-item-field / draggable
      let element = event.target.parentNode.parentNode.parentNode.parentNode;
      currentId = element.dataset.itemId;
    }

    //this.getTreeComponent().removeItem(currentId, true);
    //this.addDelayedDeleteItem(currentId, index);
    let visited = [];
    let visitedWithIndex = [];
    this.visitTreeItemChildren(currentId, index, visited, visitedWithIndex);
    if (this.getTreeComponent()) {
      this.getTreeComponent().removeItem(visited, true, true);
    }
    this.addMultipleDelayedDeleteItems(visitedWithIndex);

    this.delTreeItemNow(currentId, index);
  };

  visitTreeItemChildren = (currentId, index, visited, visitedWithIndex) => {
    visited.push(currentId);
    visitedWithIndex.push({ [index]: currentId });
    if (!this.getTreeComponent()) {
      return;
    }
    let item = this.getTreeComponent().getItem(currentId);
    if (item && item.children && item.children.length > 0) {
      item.children.map(childItemId => {
        let childItem = this.getTreeComponent().getItem(childItemId);
        let dbIndex = childItem.data.originalFieldIndex;
        this.visitTreeItemChildren(
          childItemId,
          dbIndex,
          visited,
          visitedWithIndex
        );
        return childItemId;
      });
    }
  };

  addMultipleDelayedDeleteItems = items => {
    this.setState({
      delayedDeleteItems: [...this.state.delayedDeleteItems, items]
    });
    this.delDelayedItems();
  };

  addDelayedDeleteItem = (currentId, index) => {
    this.setState({
      delayedDeleteItems: [
        ...this.state.delayedDeleteItems,
        { [index]: currentId }
      ]
    });
    this.delDelayedItems();
  };

  delDelayedItems = debounce(
    () => {
      this.state.delayedDeleteItems.map(delayedDeleteItemData => {
        let delayedDeleteItemId =
          delayedDeleteItemData[Object.keys(delayedDeleteItemData)[0]];
        this.delTreeItemNow(delayedDeleteItemId);

        return delayedDeleteItemData;
      });
    },
    100,
    { maxWait: 500 }
  );

  delTreeItemNow = (currentId, index) => {
    if (!this.getTreeComponent()) {
      return;
    }
    let deleteItem = this.getTreeComponent().getItem(currentId);
    if (deleteItem) {
      let dbId = deleteItem.data.originalData['@id'] || '';
      let dbIndex = deleteItem.data.originalFieldIndex;
      if (dbId) {
        this.getTreeComponent().removeItem(deleteItem.id, false);
        this.delTreeItemFromAction({ '@id': dbId }, dbIndex, false);
      }
    }
  };

  delTreeItemFromAction = (data, index, update = true) => {
    let del = this.props.dispatch(this.actionDeleteItem(data, index));

    if (typeof del.then !== 'function') {
      return;
    }

    if (update) {
      del
        .then(response => {
          this.updateTree();
        })
        .catch(e => {
          // do nothing
        });
    }
  };

  getEditItemReadOnlyStatus = (data, index) => {
    if (this.state.fieldEditMap.hasOwnProperty(index)) {
      return this.state.fieldEditMap[index];
    }

    let displayEditItemButton = this.props.displayEditItemButton || false;

    if (
      typeof displayEditItemButton === 'string' &&
      displayEditItemButton === 'not_editable'
    ) {
      // is read only
      // start by showing the read only field
      return true;
    }

    if (displayEditItemButton) {
      // is read only
      // start by showing the read only field
      return true;
    }

    // not read only
    // can always edit if there is no button
    return false;
  };

  editTreeItem = (data, index, event = null) => {
    let displayEditItemButton = this.props.displayEditItemButton || false;

    if (
      typeof displayEditItemButton === 'string' &&
      displayEditItemButton === 'not_editable'
    ) {
      displayEditItemButton = false;
    }

    if (!displayEditItemButton) {
      return;
    }

    this.setState(
      prevState => {
        return {
          fieldEditMap: {
            ...this.state.fieldEditMap,
            [index]: prevState.fieldEditMap.hasOwnProperty(index)
              ? !prevState.fieldEditMap[index]
              : false
          }
        };
      },
      () => {
        // as the state is updated, add/remove the readonly attribute, otherwise the field cannot be editable
        let editElems = document.getElementsByName(
          this.props.fieldName + '[' + index + '][name]'
        );
        if (editElems.length > 0 && editElems[0]) {
          if (this.state.fieldEditMap[index]) {
            editElems[0].setAttribute('readonly', true);
            let parent = editElems[0].parentNode;
            parent.classList.remove('isEditable');
          } else {
            editElems[0].removeAttribute('readonly');
            let parent = editElems[0].parentNode;
            parent.className += ' isEditable';
          }
        }
      }
    );
    if (typeof this.props.editTreeItem === 'function') {
      return this.props.editTreeItem(data, index, event, this);
    }
  };

  dragOverTreeItem = (data, index, event = null) => {
    if (typeof this.props.dragOverTreeItem === 'function') {
      return this.props.dragOverTreeItem(event, data, index, this);
    }
  };

  dropTreeItem = (data, index, event = null) => {
    if (typeof this.props.dropTreeItem === 'function') {
      return this.props.dropTreeItem(event, data, index, this);
    }
  };

  selectTreeItem = (data, index) => {
    this.setState({
      lastSelectedFieldIndex: index
    });

    let trackSelectTreeItem = this.props.trackSelectTreeItem || false;
    if (trackSelectTreeItem) {
      this.setIsSelectingTreeField(true);
    }

    setTimeout(() => {
      this.clearSelectedTreeItems();
      this.reapplySelectedTreeItems(index);
      // close edit status
      if (!this.getEditItemReadOnlyStatus(data, index)) {
        this.editTreeItem(data, index);
      }
    }, 100);

    if (typeof this.props.selectTreeItem === 'function') {
      return this.props.selectTreeItem(data, index, this);
    }
  };

  resetSelectedTreeItem = () => {
    this.setState({
      lastSelectedFieldIndex: false
    });

    setTimeout(() => {
      this.clearSelectedTreeItems();
    }, 100);

    if (typeof this.props.resetSelectedTreeItem === 'function') {
      return this.props.resetSelectedTreeItem();
    }
  };

  clearSelectedTreeItems = () => {
    // clear existing selected classes
    let selectedElems = document.getElementsByClassName('selected-item');
    Object.keys(selectedElems).map(seIndex => {
      if (!!selectedElems[seIndex]) {
        selectedElems[seIndex].className = selectedElems[
          seIndex
        ].className.replace('selected-item', '');
      }
      return seIndex;
    });
    let selectedParents = document.getElementsByClassName(
      'selected-item-parent'
    );
    Object.keys(selectedParents).map(spIndex => {
      if (!!selectedParents[spIndex]) {
        selectedParents[spIndex].className = selectedParents[
          spIndex
        ].className.replace('selected-item-parent', '');
      }
      return spIndex;
    });
  };

  reapplySelectedTreeItems = index => {
    // re-apply selected classes
    let selectedEls = document.getElementsByName(
      this.props.fieldName + '[' + index + '][name]'
    );
    if (selectedEls.length > 0 && selectedEls[0]) {
      let selectedEl = selectedEls[0];
      if (selectedEl.parentNode.className.indexOf('selected-item') === -1) {
        selectedEl.parentNode.className += ' selected-item';
      }
      // input / form-group / procedure-step / tree-item-field / draggable
      let parentEl = selectedEl.parentNode.parentNode.parentNode.parentNode;
      if (parentEl.className.indexOf('selected-item-parent') === -1) {
        parentEl.className += ' selected-item-parent';
      }
    }
  };

  getTreeItemField = (data, index) => {
    let displayFieldContainer = this.props.displayFieldContainer || false;
    let displayEditItemButton = this.props.displayEditItemButton || false;
    let displayDeleteItemButton = this.props.displayDeleteItemButton || false;
    let displaySelectItemButton = this.props.displaySelectItemButton || false;

    if (
      typeof displayEditItemButton === 'string' &&
      displayEditItemButton === 'not_editable'
    ) {
      displayEditItemButton = false;
    }

    let isFirstField =
      (data.level === 0 && data.position === 0) ||
      (data.level === 0 &&
        data.position === null &&
        document.getElementsByClassName('procedure-step').length < 2);

    let fieldPlaceholder =
      typeof isFirstField !== 'undefined' &&
      isFirstField &&
      !!this.props.firstFieldPlaceholder
        ? this.props.firstFieldPlaceholder
        : this.props.fieldPlaceholder;

    return (
      <>
        <div
          className={this.props.fieldWrapperClassName}
          style={{
            display: 'flex',
            width: displaySelectItemButton === true ? '90%' : '100%'
          }}
        >
          <Field
            component={
              displayFieldContainer
                ? this.renderFieldContainer
                : this.renderField
            }
            name={this.props.fieldName + '[' + index + '][name]'}
            type="text"
            placeholder={fieldPlaceholder}
            autoFocus={data.autoFocus || false}
            originalData={data}
            originalIndex={index}
            readOnly={this.getEditItemReadOnlyStatus(data, index)}
            style={{
              border: 0,
              textAlign: 'left',
              width: '100%'
            }}
          />
          {!displayFieldContainer && (
            <Field
              component={this.renderHiddenField}
              name={this.props.fieldName + '[' + index + '][@id]'}
            />
          )}
          {data['@id'] &&
            displayEditItemButton &&
            this.getEditItemActionComponent(data, index, event =>
              this.editTreeItem(data, index, event)
            )}
          {data['@id'] &&
            displayDeleteItemButton &&
            this.getDeleteItemActionComponent(data, index, event =>
              this.delTreeItem(data, index, true, '', event)
            )}
          {data['@id'] &&
            displaySelectItemButton &&
            this.getSelectItemActionComponent(data, index, () =>
              this.selectTreeItem(data, index)
            )}
        </div>
        {this.getAfterItemComponent(data, index)}
      </>
    );
  };

  getEditItemActionComponent = (data, index, clickHandler) => {
    if (typeof this.props.getEditItemActionComponent === 'function') {
      return this.props.getEditItemActionComponent(
        data,
        index,
        clickHandler,
        this.props.fieldName
      );
    }

    return (
      <span onClick={clickHandler} className="edit-tree-item pull-right">
        <span className={'fa fa-edit'}>
          <span className={'sr-only'}>Edit</span>
        </span>
      </span>
    );
  };

  getDeleteItemActionComponent = (data, index, clickHandler) => {
    if (typeof this.props.getDeleteItemActionComponent === 'function') {
      return this.props.getDeleteItemActionComponent(
        data,
        index,
        clickHandler,
        this.props.fieldName
      );
    }

    return (
      <span onClick={clickHandler} className="delete-tree-item pull-right">
        <span className={'fa fa-trash'}>
          <span className={'sr-only'}>Delete</span>
        </span>
      </span>
    );
  };

  getSelectItemActionComponent = (data, index, clickHandler) => {
    if (typeof this.props.getSelectItemActionComponent === 'function') {
      return this.props.getSelectItemActionComponent(
        data,
        index,
        clickHandler,
        this.props.fieldName
      );
    }

    return (
      <span onClick={clickHandler} className="select-tree-item pull-right">
        <span className={'fa fa-arrow-right'}>
          <span className={'sr-only'}>Select</span>
        </span>
      </span>
    );
  };

  getAfterItemComponent = (data, index) => {
    if (typeof this.props.getAfterItemComponent === 'function') {
      return this.props.getAfterItemComponent(
        data,
        index,
        this.props.fieldName
      );
    }

    return '';
  };

  getLoadingField = (data, index) => {
    return (
      <div
        className={this.props.fieldWrapperClassName}
        style={{
          display: 'inline-block',
          width: '100%'
        }}
      >
        <div className={`form-group`}>
          {/*
          <input
            placeholder={'Loading...'}
            disabled={true}
            style={{
              border: 0,
              textAlign: 'left',
              width: '100%'
            }}
          />
          */}
          <ProgressBar
            animated={true}
            now={100}
            label={'Loading...'}
            style={{ height: '38px' }}
          />
        </div>
      </div>
    );
  };

  attachTree = node => {
    this._tree = node;
  };

  getTreeComponent = () => {
    return this._tree;
  };

  getTreeComponentTree = () => {
    return this.getTreeComponent().state.tree;
  };

  addTreeItem = () => {
    this.setIsCreatingTreeField(true);

    let objectId =
      this.props.initialValues && this.props.initialValues['@id']
        ? this.props.initialValues['@id']
        : '';

    let create = this.props.dispatch(
      this.actionCreateItem(this.getInitialItemCreationData(objectId))
    );

    if (typeof create.then !== 'function') {
      return;
    }

    let countIndex = this.getTreeComponent().getItemCount();

    // since fields can be removed, ensure the field indexes are unique
    let existingIndexes = [];
    Object.keys(this.getTreeComponentTree().items).forEach(itId => {
      let it = this.getTreeComponentTree().items[itId];
      if (it.data && !isNaN(it.data.originalFieldIndex)) {
        existingIndexes.push(it.data.originalFieldIndex);
      }
    });
    if (existingIndexes.indexOf(countIndex) !== -1) {
      // while it would be nice to fill in the gaps once a field is removed, the
      // missing indexes must stay missing in order to prevent shifting field data,
      // so do not start the countIndex over again
      //countIndex = 0;
      while (existingIndexes.indexOf(countIndex) !== -1) {
        countIndex++;
      }
    }

    let idNumber =
      countIndex + (Math.floor(Math.random() * (50000 - 10000 + 1)) + 10000);
    let itemId = this.getTreeComponentTree().rootId + '-' + idNumber;

    let item = { '@id': '', ...this.getInitialItemCreationData(objectId) };

    this.getTreeComponent().addItem(idNumber, {
      field: this.getLoadingField({ ...item, autoFocus: true }, countIndex),
      isRemoved: false,
      originalFieldIndex: countIndex,
      originalFieldValue: null, // placeholder for TreeBuilder
      originalId: item['@id'],
      originalData: item
    });

    return create
      .then(response => {
        let item =
          response && response.created
            ? response.created
            : { '@id': '', ...this.getInitialItemCreationData(objectId) };

        let newIdNumber = item['@id'].replace(/[^\d]*/, '');

        this.props.dispatch(
          this.props.change(
            this.props.fieldName + '[' + newIdNumber + '][@id]',
            item['@id']
          )
        );

        this.getTreeComponent().updateItemData(itemId, {
          field: this.getTreeItemField(
            { ...item, autoFocus: true },
            newIdNumber
          ),
          isRemoved: false,
          originalFieldIndex: newIdNumber,
          originalFieldValue: null, // placeholder for TreeBuilder
          originalId: item['@id'],
          originalData: item
        });

        this.setHasFirstItem(true);

        this.editTreeItem(item, newIdNumber);
      })
      .catch(e => {
        // do nothing
      });
  };

  addTreeItemForParentAtIndex = (parentId, index) => {
    this.setIsCreatingTreeField(true);

    let objectId =
      this.props.initialValues && this.props.initialValues['@id']
        ? this.props.initialValues['@id']
        : '';

    let create = this.props.dispatch(
      this.actionCreateItem(this.getInitialItemCreationData(objectId))
    );

    if (typeof create.then !== 'function') {
      return;
    }

    let countIndex = this.getTreeComponent().getItemCount();

    // since fields can be removed, ensure the field indexes are unique
    let existingIndexes = [];
    Object.keys(this.getTreeComponentTree().items).forEach(itId => {
      let it = this.getTreeComponentTree().items[itId];
      if (it.data && !isNaN(it.data.originalFieldIndex)) {
        existingIndexes.push(it.data.originalFieldIndex);
      }
    });
    if (existingIndexes.indexOf(countIndex) !== -1) {
      // while it would be nice to fill in the gaps once a field is removed, the
      // missing indexes must stay missing in order to prevent shifting field data,
      // so do not start the countIndex over again
      //countIndex = 0;
      while (existingIndexes.indexOf(countIndex) !== -1) {
        countIndex++;
      }
    }

    let idNumber =
      countIndex + (Math.floor(Math.random() * (50000 - 10000 + 1)) + 10000);
    let itemId = parentId + '-' + idNumber;

    let item = { '@id': '', ...this.getInitialItemCreationData(objectId) };

    this.getTreeComponent().addItemForParentAtIndex(idNumber, parentId, index, {
      field: this.getLoadingField({ ...item, autoFocus: true }, countIndex),
      isRemoved: false,
      originalFieldIndex: countIndex,
      originalFieldValue: null, // placeholder for TreeBuilder
      originalId: item['@id'],
      originalData: item
    });

    return create
      .then(response => {
        let item =
          response && response.created
            ? response.created
            : { '@id': '', ...this.getInitialItemCreationData(objectId) };

        let newIdNumber = item['@id'].replace(/[^\d]*/, '');

        this.props.dispatch(
          this.props.change(
            this.props.fieldName + '[' + newIdNumber + '][@id]',
            item['@id']
          )
        );

        this.getTreeComponent().updateItemData(itemId, {
          field: this.getTreeItemField(
            { ...item, autoFocus: true },
            newIdNumber
          ),
          isRemoved: false,
          originalFieldIndex: newIdNumber,
          originalFieldValue: null, // placeholder for TreeBuilder
          originalId: item['@id'],
          originalData: item
        });

        this.setHasFirstItem(true);

        this.editTreeItem(item, newIdNumber);
      })
      .catch(e => {
        // do nothing
      });
  };

  updateTree = () => {
    let id =
      this.props.initialValues && this.props.initialValues['@id']
        ? this.props.initialValues['@id']
        : '';
    if (!id) {
      return;
    }

    let retrieve = this.props.dispatch(this.actionRetrieveItem(id));

    if (typeof retrieve.then !== 'function') {
      return;
    }

    return retrieve
      .then(response => {
        let object = response && response.retrieved ? response.retrieved : {};

        if (this.hasTreeData(object)) {
          return getStepTree(
            this.getTreeData(object),
            this.getTreeItemField,
            this.state.delayedDeleteItems,
            this.state.expansionMap
          );
        }

        return this.getTreeComponentTree();
      })
      .then(tree => {
        this.getTreeComponent().setState({ tree });
      })
      .catch(e => {
        // do nothing
      });
  };

  onTreeUpdated = (newTree, returnObject = true) => {
    let id =
      this.props.initialValues && this.props.initialValues['@id']
        ? this.props.initialValues['@id']
        : '';

    let storeTree = this.props.dispatch(
      this.actionStoreTree({
        returnObject: returnObject,
        currentUrl: id ? id : window.location.href,
        tree: removeFieldWithinTree(newTree, [
          'hasChildren',
          'isExpanded',
          'isChildrenLoading'
        ])
      })
    );

    return storeTree;
  };

  onDragEndTreeUpdated = (newTree, source, destination, component) => {
    // store the recently dragged item's expanded status
    let destId =
      newTree.items[destination.parentId].children[destination.index];
    let destItem = newTree.items[destId];
    let destExpanded = !!(
      destItem &&
      destItem.data &&
      destItem.data.isExpanded
    );
    if (destExpanded) {
      this.onItemExpand(destId);
    } else {
      this.onItemCollapse(destId);
    }

    let storeTree = this.onTreeUpdated(newTree, true);

    if (typeof storeTree.then !== 'function') {
      return;
    }

    return storeTree
      .then(response => {
        let object = response && response.retrieved ? response.retrieved : {};

        if (this.hasTreeData(object)) {
          return getStepTree(
            this.getTreeData(object),
            this.getTreeItemField,
            this.state.delayedDeleteItems,
            this.state.expansionMap
          );
        }

        return newTree;
      })
      .then(tree => {
        component.setState({ tree });
      })
      .catch(e => {
        // do nothing
      });
  };

  onItemExpand = itemId => {
    this.setState({
      expansionMap: { ...this.state.expansionMap, [itemId]: true }
    });
  };

  onItemCollapse = itemId => {
    this.setState({
      expansionMap: { ...this.state.expansionMap, [itemId]: false }
    });
  };

  hasTreeData = data => {
    if (typeof this.props.hasTreeData === 'function') {
      return this.props.hasTreeData(data);
    }

    return false;
  };

  getTreeData = data => {
    if (typeof this.props.hasTreeData === 'function') {
      return this.props.getTreeData(data);
    }

    return [];
  };

  getInitialItemCreationData = objectId => {
    if (typeof this.props.getInitialItemCreationData === 'function') {
      return this.props.getInitialItemCreationData(objectId);
    }

    return { name: '' };
  };

  actionCreateItem = values => {
    if (typeof this.props.actionCreateItem === 'function') {
      return this.props.actionCreateItem(values);
    }

    return {};
  };

  actionRetrieveItem = id => {
    if (typeof this.props.actionRetrieveItem === 'function') {
      return this.props.actionRetrieveItem(id);
    }

    return {};
  };

  actionDeleteItem = (item, index) => {
    if (typeof this.props.actionDeleteItem === 'function') {
      return this.props.actionDeleteItem(item, index);
    }

    return {};
  };

  actionStoreTree = values => {
    if (typeof this.props.actionStoreTree === 'function') {
      return this.props.actionStoreTree(values);
    }

    return {};
  };

  getFieldValueAtIndex = index => {
    if (typeof this.props.getFieldValueAtIndex === 'function') {
      return this.props.getFieldValueAtIndex(index);
    }

    return null;
  };

  render() {
    let tree = getStepTree(
      this.getTreeData(this.props.initialValues),
      this.getTreeItemField,
      this.state.delayedDeleteItems,
      this.state.expansionMap
    );

    let addButton = (
      <button
        id={this.props.addElementId}
        onClick={this.addTreeItem}
        style={{
          textAlign: 'center'
        }}
      >
        <i className={'fa fa-plus'}></i> {this.props.addElementLabel}
      </button>
    );

    return (
      <div
        className={
          'top-tree-wrapper' +
          (this.props.fieldName ? ' ' + this.props.fieldName : '') +
          (this.props.fieldName === 'procedureSteps'
            ? ' d-flex form-legend-container'
            : ' ')
        }
      >
        <div className={'top-tree-header'}>
          <p>Completed By</p> <p>Completed</p>
        </div>
        <div
          className={
            'tree-wrapper' +
            (this.props.fieldName ? ' ' + this.props.fieldName : '')
            // to close the procedure wrapper by default...
            // +
            // (this.props.fieldName === 'procedureSteps'
            //   ? ' toggle-procedure-steps'
            //   : '')
          }
          id={this.props.fieldName ? this.props.fieldName + '-id' : ''}
        >
          {!!this.props.displayAddButtonOnTop &&
            !!this.props.displayAddButton &&
            addButton}
          <DragDropWithNestingTree
            tree={tree}
            onTreeUpdated={this.onTreeUpdated}
            onDragEndTreeUpdated={this.onDragEndTreeUpdated}
            onItemExpand={this.onItemExpand}
            onItemCollapse={this.onItemCollapse}
            itemWidth={this.props.itemWidth}
            iconParentOpenClass={this.props.iconParentOpenClass}
            iconParentClosedClass={this.props.iconParentClosedClass}
            isDraggingEnabled={this.props.isDraggingEnabled}
            getFieldValueAtIndex={this.getFieldValueAtIndex}
            ref={this.attachTree.bind(this)}
          />
          {!this.props.displayAddButtonOnTop &&
            !!this.props.displayAddButton &&
            addButton}
        </div>
        {this.props.fieldName === 'procedureSteps' ? (
          <div className={'flex-column flex-basis-20'}>
            {this.props.sidebarComponent}
            <KeyboardShortcuts />
          </div>
        ) : null}
      </div>
    );
  }
}

export default TreeFormFields;
