import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { change as changeFormField } from 'redux-form';

import {
  clone as cloneProcedure,
  reset as cloneReset,
  success as cloneSuccess
} from '../../actions/procedure/clone';
import { del, success as delSuccess } from '../../actions/procedure/delete';
import { list, reset } from '../../actions/procedure/list';
import { update as procedureUpdate } from '../../actions/procedure/update';
import {
  create as createUsedProcedure,
  success as successUsedProcedure
} from '../../actions/usedprocedure/create';
import {
  filterSerialize,
  getFilterValues,
  getPageWithFilterValues,
  setFilterValues
} from '../../utils/listFilter';
import CategoryTreeSelectionForm from '../category/UpdateTree';
import CurrentUserCan, { checkCurrentUserCan } from '../CurrentUserCan';
import { VersionLink as Link } from '../VersionLink';
import FilterForm from './FilterForm';

class List extends Component {
  state = {
    procedureModals: {}
  };

  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    deletedItem: PropTypes.object,
    list: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.list(
      this.getPageWithFilterValues(
        this.props.match.params.page &&
          decodeURIComponent(this.props.match.params.page)
      )
    );
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.page !== nextProps.match.params.page)
      nextProps.list(
        this.getPageWithFilterValues(
          nextProps.match.params.page &&
            decodeURIComponent(nextProps.match.params.page)
        )
      );
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
    this.props.cloneReset(this.props.eventSource);
  }

  del = item => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      this.props.del(item);
      this.props.list(
        this.getPageWithFilterValues(
          this.props.match.params.page &&
            decodeURIComponent(this.props.match.params.page)
        )
      );
    }
  };

  delNow = (item, event) => {
    event.target.disabled = true;
    event.target.innerHTML += '<span class="fa fa-spinner fa-spin"></span>';

    let delItem = this.props.del(item);

    if (typeof delItem.then === 'function') {
      delItem.then(() => {
        this.props.list(
          this.getPageWithFilterValues(
            this.props.match.params.page &&
              decodeURIComponent(this.props.match.params.page)
          )
        );

        this.handleClose({ '@id': 'delete_' + item['@id'] });

        this.props.delReset();
      });
    }
  };

  setShow = (procedure, value) => {
    this.setState({
      procedureModals: {
        ...this.state.procedureModals,
        [procedure['@id']]: value
      }
    });
  };

  handleClose = procedure => this.setShow(procedure, false);

  handleShow = procedure => this.setShow(procedure, true);

  selectRootItem = (event, data, component) => {
    this.props.changeFormField('procedureFilter', 'category.id', '');

    setFilterValues('lastProcedureCategory', {});

    component.getTreeComponent().getTreeComponent().resetSelectedTreeItem();

    setTimeout(() => {
      component.getTreeComponent().getTreeComponent().clearSelectedTreeItems();
      component.reapplySelectedTreeRootItem();

      let submitButton = document.getElementById('hidden-submit-filter-button');
      if (submitButton) {
        submitButton.click(); // handleBlur
      }

      component.getTreeComponent().getTreeComponent().clearSelectedTreeItems();
      component.reapplySelectedTreeRootItem();
    }, 1000);
  };

  selectTreeItem = (data, index, component) => {
    this.props.changeFormField('procedureFilter', 'category.id', data['@id']);

    setFilterValues('lastProcedureCategory', data);

    setTimeout(() => {
      let submitButton = document.getElementById('hidden-submit-filter-button');
      if (submitButton) {
        submitButton.click(); // handleBlur
      }
    }, 100);
  };

  pressFormSubmit = () => {
    //
  };

  pressFormReset = () => {
    this.setFilterValues({});
    this.localClearSelectedTreeItems();
    this.localReapplySelectedTreeRootItem();

    setFilterValues('lastProcedureCategory', {});

    this.props.list(
      this.getPageWithFilterValues(
        this.props.match.params.page &&
          decodeURIComponent(this.props.match.params.page)
      )
    );

    setTimeout(() => {
      this.localClearSelectedTreeItems();
      this.localReapplySelectedTreeRootItem();
    }, 1000);
  };

  // @see TreeFormFields.clearSelectedTreeItems
  localClearSelectedTreeItems = () => {
    // clear existing selected classes
    let selectedElems = document.getElementsByClassName('selected-item');
    Object.keys(selectedElems).map(seIndex => {
      if (!!selectedElems[seIndex]) {
        selectedElems[seIndex].className = selectedElems[
          seIndex
        ].className.replace('selected-item', '');
      }
      return seIndex;
    });
    let selectedParents = document.getElementsByClassName(
      'selected-item-parent'
    );
    Object.keys(selectedParents).map(spIndex => {
      if (!!selectedParents[spIndex]) {
        selectedParents[spIndex].className = selectedParents[
          spIndex
        ].className.replace('selected-item-parent', '');
      }
      return spIndex;
    });
  };

  // @see TreeForm.reapplySelectedTreeRootItem
  localReapplySelectedTreeRootItem = () => {
    // re-apply selected classes
    let selectedEls = document.getElementsByName('name');
    if (selectedEls.length > 0 && selectedEls[0]) {
      let selectedEl = selectedEls[0];
      if (selectedEl.parentNode.className.indexOf('selected-item') === -1) {
        selectedEl.parentNode.className += ' selected-item';
      }
      // input / form-group / tree-root-item
      let parentEl = selectedEl.parentNode.parentNode;
      if (parentEl.className.indexOf('selected-item-parent') === -1) {
        parentEl.className += ' selected-item-parent';
      }
    }
  };

  getSortableHeader = (field, startDirection) => {
    let page = decodeURIComponent(
      this.getPageWithFilterValues(
        this.props.retrieved['hydra:view']
          ? this.props.retrieved['hydra:view']['@id']
          : '/procedures'
      )
    );

    let queryString =
      field + '=' + (startDirection === 'desc' ? 'desc' : 'asc');

    if (page.indexOf(queryString) === -1) {
      if (page.indexOf('order[') !== -1) {
        page = page.replace(/[?&]order[[][^\]]+[\]]=(asc|desc)/, '');
      }
      page = page + (page.indexOf('?') === -1 ? '?' : '&') + queryString;
    } else {
      let queryStringAsc = field + '=asc';
      let queryStringDesc = field + '=desc';

      if (page.indexOf(queryStringAsc) !== -1) {
        if (page.indexOf('order[') !== -1) {
          page = page.replace(/[?&]order[[][^\]]+[\]]=(asc|desc)/, '');
        }
        page = page + (page.indexOf('?') === -1 ? '?' : '&') + queryStringDesc;
      } else if (page.indexOf(queryStringDesc) !== -1) {
        if (page.indexOf('order[') !== -1) {
          page = page.replace(/[?&]order[[][^\]]+[\]]=(asc|desc)/, '');
        }
        page = page + (page.indexOf('?') === -1 ? '?' : '&') + queryStringAsc;
      }
    }

    return encodeURIComponent(page);
  };

  getPageWithFilterValues = page => {
    return getPageWithFilterValues(
      'procedureFilterValues',
      page,
      '/procedures'
    );
  };

  getFilterValues = () => {
    return getFilterValues('procedureFilterValues');
  };

  setFilterValues = values => {
    setFilterValues('procedureFilterValues', values);
  };

  isFiltered = () => {
    let filtered = this.getFilterValues();

    if (
      //!!filtered.search_text ||
      !!filtered.name ||
      !!filtered.author ||
      !!(filtered.category && filtered.category.id) ||
      !!filtered.is_my_procedure
    ) {
      return true;
    }

    return false;
  };

  serialize = (obj, prefix) => {
    return filterSerialize(obj, prefix, (prefix, p) => {
      if (prefix.indexOf('tags') !== -1) {
        return false;
      }
      return true;
    });
  };

  filter = (filtered, values) => {
    this.setFilterValues(values);

    let queryString = this.serialize(values);

    this.props.list('/procedures?' + queryString);
  };

  useProcedure = (item, event) => {
    event.preventDefault();
    this.props.createUsedProcedure({ procedure: item['@id'] });
  };

  cloneProcedure = (item, event) => {
    event.preventDefault();
    this.props.cloneProcedure(item);
  };

  // https://stackoverflow.com/questions/57482068/react-antdesign-how-to-make-rows-draggable-table-drag-sorting
  dragstart = event => {
    let dataTransfer = event.dataTransfer;
    let node = event.target;
    dataTransfer.setData('id', node.id);
    event.stopPropagation();
  };

  dragend = event => {
    event.preventDefault();

    this.clearDraggedOver();

    // input / form-group / category / tree-item-field / item
    event.target.parentNode.parentNode.parentNode.parentNode.classList.add(
      'is-dragged-over'
    );
  };

  drop = event => {
    event.stopPropagation();
    event.preventDefault();
    let dragObjId = event.dataTransfer.getData('id');
    let element = event.target;

    this.clearDraggedOver();

    if (dragObjId && element && element.value) {
      let update = this.props.procedureUpdate(
        { '@id': dragObjId },
        { categoryName: element.value }
      );
      if (typeof update.then === 'function') {
        update.then(() => {
          this.props.list(
            this.getPageWithFilterValues(
              this.props.match.params.page &&
                decodeURIComponent(this.props.match.params.page)
            )
          );
        });
      }
    }

    return false;
  };

  clearDraggedOver = () => {
    let elems = document.getElementsByClassName('is-dragged-over');
    Object.keys(elems).map(itemId => {
      let item = elems[itemId];
      item.classList.remove('is-dragged-over');
      return itemId;
    });
  };

  onNavigateToEdit = () => {
    setTimeout(() => {
      // on the next page, scroll to the top
      window.scrollTo(0, 0);
    }, 500);
  };

  render() {
    if (this.props.usedProcedureCreated) {
      let usedProcedureCreatedId = this.props.usedProcedureCreated['@id'];
      this.props.successUsedProcedure(null);
      return (
        <Redirect
          to={`/used_procedures/edit/${encodeURIComponent(
            usedProcedureCreatedId
          )}`}
          push={true}
        />
      );
    }
    if (this.props.procedureCloned) {
      setTimeout(() => {
        // on the next page, focus on the name field to allow the user to quickly edit it
        let elems = document.getElementsByName('name');
        if (elems && elems.length > 0) {
          window.scrollTo(0, 0);
          elems[0].focus();
          elems[0].setSelectionRange(0, elems[0].value.length);
        }
      }, 1100);

      let procedureClonedId = this.props.procedureCloned['@id'];
      this.props.cloneSuccess(null);
      return (
        <Redirect
          to={`/procedures/edit/${encodeURIComponent(procedureClonedId)}`}
          push={true}
        />
      );
    }

    let lastSelectedCategoryId = false;
    let filtered = this.getFilterValues();
    if (filtered && filtered.category && filtered.category.id) {
      lastSelectedCategoryId = filtered.category.id.replace(/[^\d]*/, '');
    }

    let lastProcedureCategory = '';
    let lastProcedureCategoryData = getFilterValues('lastProcedureCategory');
    if (lastProcedureCategoryData && lastProcedureCategoryData.name) {
      lastProcedureCategory = ' in ' + lastProcedureCategoryData.name;
    }

    return (
      <div className={'page-list page-procedure-list mt-3'}>
        {/*<nav className={'nav'}>*/}
        {/*  <Link className={'nav-link h1 active'} to={'/procedures/'}>*/}
        {/*    <h1>Procedure Templates</h1>*/}
        {/*  </Link>*/}
        {/*  <Link className={'nav-link h1'} to={'/used_procedures/'}>*/}
        {/*    <span>Procedures In Use</span>*/}
        {/*  </Link>*/}
        {/*  <Link className={'nav-link h1'} to={'/used_procedures/completed/'}>*/}
        {/*    <span>Completed Procedures</span>*/}
        {/*  </Link>*/}
        {/*</nav>*/}

        {/*
        {this.props.loading && (
          <div className="alert alert-info">Loading...</div>
        )}
        */}
        {this.props.loading && (
          <div className="pull-right" role="status">
            <div style={{ position: 'relative' }}>
              <div style={{ position: 'absolute', top: 0, left: 0 }}>
                Loading...
              </div>
            </div>
          </div>
        )}
        {this.props.deletedItem && (
          <div className="alert alert-success">
            {this.props.deletedItem['@id']} deleted.
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger">{this.props.error}</div>
        )}

        {/*
        <p>
          <Link to="create" className="btn btn-primary">
            Create
          </Link>
        </p>
        */}

        <div className={'procedureTemplateListContainer row ml-0 mr-0'}>
          <div className={'col-md-3 ml-0 pl-0 mr-0 pr-0'}>
            <CategoryTreeSelectionForm
              isDraggingEnabled={checkCurrentUserCan(
                this.props.currentUserAccount,
                'category:edit'
              )}
              displayAddButton={checkCurrentUserCan(
                this.props.currentUserAccount,
                'category:create'
              )}
              displayEditItemButton={checkCurrentUserCan(
                this.props.currentUserAccount,
                'category:edit',
                'editable',
                'not_editable'
              )}
              displayDeleteItemButton={checkCurrentUserCan(
                this.props.currentUserAccount,
                'category:delete'
              )}
              displaySelectItemButton={false}
              lastSelectedFieldIndex={lastSelectedCategoryId}
              selectRootItem={this.selectRootItem}
              selectTreeItem={this.selectTreeItem}
              trackSelectTreeItem={true}
              allowEnterKey={false}
              dragOverTreeItem={this.dragend}
              dropTreeItem={this.drop}
            />
          </div>
          <div className={'col-md-9 mr-0 pr-0 ml-0 pl-0'}>
            <div className={'mt-2 mb-2 ml-2 mr-2'}>
              <div className={'filter-form'}>
                <FilterForm
                  onSubmit={values => this.filter(filtered, values)}
                  initialValues={filtered}
                  pressFormSubmit={this.pressFormSubmit}
                  pressFormReset={this.pressFormReset}
                />
                <CurrentUserCan
                  perform={'procedure:create'}
                  yes={() => (
                    <Link to="create" className="btn btn-cta">
                      <i class={'fa fa-plus'}></i>
                      Create Template
                      <span id={'create-new-procedure-category-text'}>
                        {lastProcedureCategory}
                      </span>
                    </Link>
                  )}
                />
              </div>
              {this.props.retrieved &&
              this.props.retrieved['hydra:member'] &&
              this.props.retrieved['hydra:member'].length > 0 ? (
                <div className="procedure-list-table-wrapper">
                  <table className="table table-responsive table-striped table-hover">
                    <thead>
                      <tr>
                        <th>
                          <Link
                            to={this.getSortableHeader('order[name]', 'asc')}
                          >
                            Title
                          </Link>
                        </th>
                        <th id={'tooltip-parent'}>
                          <div className="d-flex">
                            Folder
                            <CurrentUserCan
                              perform={'procedure:edit'}
                              yes={() => (
                                <div>
                                  <button>
                                    <i className="fa fa-question-circle"></i>
                                  </button>
                                  <span id="procedure-list-tooltip">
                                    {/*You can drag & drop a procedure into a*/}
                                    You can drag & drop a template into a folder
                                    at left to organize it!
                                  </span>
                                </div>
                              )}
                            />
                          </div>
                        </th>
                        <th>
                          <Link
                            to={this.getSortableHeader(
                              'order[createdBy.name]',
                              'asc'
                            )}
                          >
                            Author
                          </Link>
                        </th>
                        <th>
                          <Link
                            to={this.getSortableHeader(
                              'order[updatedAt]',
                              'asc'
                            )}
                          >
                            Updated
                          </Link>
                        </th>
                        <th>Updated By</th>
                        <th colSpan={2}>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.retrieved &&
                        this.props.retrieved['hydra:member'].map(item => (
                          <tr>
                            <th
                              scope="row"
                              key={item['@id']}
                              draggable={true}
                              onDragStart={this.dragstart}
                              id={item['@id']}
                            >
                              <i className="fa fa-bars"></i>
                              {item['name']}
                            </th>
                            <td>{item['categoryName']}</td>
                            <td>{item['createdByName']}</td>
                            <td>{item['updatedAtDate']}</td>
                            <td>{item['updatedByName']}</td>
                            {/*
                          <td>
                            <Link to={`show/${encodeURIComponent(item['@id'])}`}>
                              <span className="fa fa-search" aria-hidden="true" />
                              <span className="sr-only">Show</span>
                            </Link>
                          </td>
                          */}
                            <td>
                              <Link
                                className={'primary-action'}
                                to={'#'}
                                onClick={event =>
                                  this.useProcedure(item, event)
                                }
                              >
                                <span
                                  className="fa fa-list"
                                  aria-hidden="true"
                                />
                                <span>Use</span>
                              </Link>
                              <CurrentUserCan
                                perform={'procedure:edit'}
                                yes={() => (
                                  <Link
                                    className={'edit-action'}
                                    to={`edit/${encodeURIComponent(
                                      item['@id']
                                    )}`}
                                    onClick={this.onNavigateToEdit}
                                  >
                                    <span
                                      className="fa fa-edit"
                                      aria-hidden="true"
                                    />
                                    <span>Edit</span>
                                  </Link>
                                )}
                              />
                            </td>
                            {/*
                          <td>
                            <Link to={`edit/${encodeURIComponent(item['@id'])}`}>
                              <span className="fa fa-pencil" aria-hidden="true" />
                              <span className="sr-only">Edit</span>
                            </Link>
                          </td>
                          */}
                            <td>
                              <CurrentUserCan
                                perform={'procedure:edit'}
                                yes={() => (
                                  <DropdownButton
                                    id={
                                      'dropdown-basic-button-' +
                                      item['@id'].replace(/[^\d]*/, '')
                                    }
                                    title={
                                      <span className={'fa fa-ellipsis-v'}>
                                        <span className={'sr-only'}>
                                          Actions
                                        </span>
                                      </span>
                                    }
                                  >
                                    {/*
                                <Link
                                  className={'dropdown-item view-action'}
                                  to={`show/${encodeURIComponent(item['@id'])}`}
                                >
                                  <span
                                    className="fa fa-eye"
                                    aria-hidden="true"
                                  />{' '}
                                  <span>View</span>
                                </Link>
                                */}
                                    <CurrentUserCan
                                      perform={'procedure:clone'}
                                      yes={() => (
                                        <Link
                                          className={
                                            'dropdown-item clone-action'
                                          }
                                          to={'#'}
                                          onClick={event =>
                                            this.cloneProcedure(item, event)
                                          }
                                        >
                                          <span
                                            className="fa fa-copy"
                                            aria-hidden="true"
                                          />{' '}
                                          <span>Duplicate</span>
                                        </Link>
                                      )}
                                    />
                                    <CurrentUserCan
                                      perform={'procedure:delete'}
                                      yes={() => (
                                        <>
                                          <button
                                            className={
                                              'dropdown-item delete-action'
                                            }
                                            onClick={() =>
                                              this.handleShow({
                                                '@id': 'delete_' + item['@id']
                                              })
                                            }
                                          >
                                            <span
                                              className="fa fa-trash"
                                              aria-hidden="true"
                                            />{' '}
                                            <span>Delete</span>
                                          </button>
                                          <Modal
                                            show={
                                              this.state.procedureModals[
                                                'delete_' + item['@id']
                                              ] || false
                                            }
                                            onHide={() =>
                                              this.handleClose({
                                                '@id': 'delete_' + item['@id']
                                              })
                                            }
                                            centered
                                            size="xl"
                                          >
                                            <Modal.Header closeButton>
                                              <Modal.Title>
                                                Are you sure you want to delete
                                                this Procedure?
                                              </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                              <p>This will delete:</p>
                                              <ul>
                                                <li>This Procedure</li>
                                                <li>
                                                  All Steps in this Procedure
                                                </li>
                                              </ul>
                                            </Modal.Body>
                                            <Modal.Footer>
                                              <button
                                                className={'btn'}
                                                onClick={() =>
                                                  this.handleClose({
                                                    '@id':
                                                      'delete_' + item['@id']
                                                  })
                                                }
                                              >
                                                Cancel
                                              </button>
                                              <button
                                                className={'btn btn-danger'}
                                                onClick={event =>
                                                  this.delNow(item, event)
                                                }
                                              >
                                                {/* Delete Procedure */}
                                                Delete Template
                                              </button>
                                            </Modal.Footer>
                                          </Modal>
                                        </>
                                      )}
                                    />
                                  </DropdownButton>
                                )}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className={'no-results'}>
                  {this.isFiltered() ? (
                    // <p>No Procedure Templates match your filter.</p>
                    <p>No Templates match your filter.</p>
                  ) : (
                    <p>
                      {/* You don't seem to have any procedure templates.{' '} */}
                      You don't seem to have any templates.{' '}
                      <CurrentUserCan
                        perform={'procedure:create'}
                        yes={() => (
                          <Link to={'/procedures/create'}>
                            {/* Click here to start your procedure template. */}
                            Click here to start your template.
                          </Link>
                        )}
                      />
                    </p>
                  )}
                </div>
              )}

              {this.props.retrieved &&
                this.props.retrieved['hydra:member'] &&
                this.props.retrieved['hydra:member'].length > 0 &&
                this.pagination()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  pagination() {
    const view = this.props.retrieved && this.props.retrieved['hydra:view'];
    if (!view) return;

    const {
      'hydra:first': first,
      'hydra:previous': previous,
      'hydra:next': next,
      'hydra:last': last
    } = view;

    if (!first && !previous && !next && !last) {
      return;
    }

    return (
      <nav aria-label="Page navigation">
        <Link
          to="."
          className={`btn btn-primary${previous ? '' : ' disabled'}`}
        >
          <span aria-hidden="true">&lArr;</span> First
        </Link>
        <Link
          to={
            !previous || previous === first
              ? '.'
              : encodeURIComponent(this.getPageWithFilterValues(previous))
          }
          className={`btn btn-primary${previous ? '' : ' disabled'}`}
        >
          <span aria-hidden="true">&larr;</span> Previous
        </Link>
        <Link
          to={
            next ? encodeURIComponent(this.getPageWithFilterValues(next)) : '#'
          }
          className={`btn btn-primary${next ? '' : ' disabled'}`}
        >
          Next <span aria-hidden="true">&rarr;</span>
        </Link>
        <Link
          to={
            last ? encodeURIComponent(this.getPageWithFilterValues(last)) : '#'
          }
          className={`btn btn-primary${next ? '' : ' disabled'}`}
        >
          Last <span aria-hidden="true">&rArr;</span>
        </Link>
      </nav>
    );
  }

  renderLinks = (type, items) => {
    if (Array.isArray(items)) {
      return items.map((item, i) => (
        <div key={i}>{this.renderLinks(type, item)}</div>
      ));
    }

    return (
      <Link to={`../${type}/show/${encodeURIComponent(items)}`}>{items}</Link>
    );
  };
}

const mapStateToProps = state => {
  const { retrieved, loading, error, eventSource, deletedItem } =
    state.procedure.list;
  return {
    currentUserAccount: state.account.show.retrieved,
    procedureCloned: state.procedure.clone.cloned,
    usedProcedureCreated: state.usedprocedure.create.created,
    retrieved,
    loading,
    error,
    eventSource,
    deletedItem
  };
};

const mapDispatchToProps = dispatch => ({
  delReset: () => dispatch(delSuccess(null)),
  cloneProcedure: item => dispatch(cloneProcedure(item)),
  cloneSuccess: values => dispatch(cloneSuccess(values)),
  cloneReset: eventSource => dispatch(cloneReset(eventSource)),
  createUsedProcedure: values => dispatch(createUsedProcedure(values)),
  successUsedProcedure: values => dispatch(successUsedProcedure(values)),
  changeFormField: (form, field, value) =>
    dispatch(changeFormField(form, field, value)),
  procedureUpdate: (item, values) => dispatch(procedureUpdate(item, values)),
  del: item => dispatch(del(item)),
  list: page => dispatch(list(page)),
  reset: eventSource => dispatch(reset(eventSource))
});

export default connect(mapStateToProps, mapDispatchToProps)(List);
