export function isActiveCurrentOrganizationSubscriptionForAccount(account) {
  let isActive = false;

  const currentOrganization = getCurrentOrganizationForAccount(account);

  const currentDate = new Date();
  const now = currentDate.getTime();

  if (
    !!currentOrganization &&
    !!currentOrganization.stripeSubscriptionIdentifier &&
    !!currentOrganization.stripeCurrentPeriodEnd &&
    !isNaN(currentOrganization.stripeCurrentPeriodEnd) &&
    currentOrganization.stripeCurrentPeriodEnd * 1000 - now > 0
  ) {
    isActive = true;
  }

  return isActive;
}

export function getCurrentOrganizationForAccount(account) {
  let currentOrganization = null;

  if (
    !!account &&
    !!account.organizationUsers &&
    !!account['@_organizationCurrentId']
  ) {
    account.organizationUsers.map(organizationUser => {
      if (
        !!organizationUser.organization &&
        !!organizationUser.organization['@id'] &&
        organizationUser.organization['@id'] ===
          account['@_organizationCurrentId']
      ) {
        currentOrganization = organizationUser.organization;
      }

      return organizationUser;
    });
  }

  return currentOrganization;
}

export function getCurrentOrganizationSubscriptionForAccount(account) {
  let currentOrganizationSubscription = null;

  if (!!account && !!account['@_organizationCurrentSubscription']) {
    currentOrganizationSubscription =
      account['@_organizationCurrentSubscription'];
  }

  return currentOrganizationSubscription;
}

export function getSubscriptionNextInvoiceDate(subscription) {
  if (
    subscription &&
    subscription.hasOwnProperty('current_period_end') &&
    !isNaN(subscription.current_period_end) &&
    subscription.current_period_end > 0
  ) {
    return new Date(subscription.current_period_end * 1000);
  }

  return null;
}

export function getSubscriptionTrialDays(subscription) {
  return (
    subscription &&
    Math.floor(
      Math.abs(subscription.trial_end * 1000 - new Date().getTime()) /
        1000 /
        86400
    )
  );
}

export function isSubscriptionStatusDuringTrial(subscription) {
  return (
    subscription && subscription.status && 'trialing' === subscription.status
  );
}

export function isSubscriptionStatusAtLimit(subscription) {
  return (
    subscription &&
    subscription.status &&
    'active' === subscription.status &&
    subscription.quantity - subscription.used_quantity <= 0
  );
}

export function isSubscriptionStatusBelowLimit(subscription) {
  return (
    subscription &&
    subscription.status &&
    'active' === subscription.status &&
    subscription.quantity - subscription.used_quantity > 0
  );
}

export function isSubscriptionStatusTrialEnded(subscription) {
  const trialDays = getSubscriptionTrialDays(subscription);

  return (
    subscription &&
    subscription.status &&
    'trialing' !== subscription.status &&
    'active' !== subscription.status &&
    trialDays <= 0
  );
}

export function isSubscriptionStatusInvalid(subscription) {
  return (
    subscription &&
    subscription.status &&
    'trialing' !== subscription.status &&
    'active' !== subscription.status
  );
}

export function isSubscriptionStatusCanceled(subscription) {
  return (
    subscription && subscription.status && 'canceled' === subscription.status
  );
}
