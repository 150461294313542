import React from 'react';
import { Route } from 'react-router-dom';

import {
  Create,
  List,
  Show,
  Update
} from '../components/purchasableprocedure/';

export default [
  <Route
    path="/purchasable_procedures/create"
    component={Create}
    exact
    key="create"
  />,
  <Route
    path="/purchasable_procedures/edit/:id"
    component={Update}
    exact
    key="update"
  />,
  <Route
    path="/purchasable_procedures/show/:id"
    component={Show}
    exact
    key="show"
  />,
  <Route
    path="/purchasable_procedures/"
    component={List}
    exact
    strict
    key="list"
  />,
  <Route
    path="/purchasable_procedures/:page"
    component={List}
    exact
    strict
    key="page"
  />
];
