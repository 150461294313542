import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';

import {
  isAdminForOrganization,
  isManagerForOrganization,
  isUserForOrganization
} from '../../utils/auth';
import {
  getSubscriptionNextInvoiceDate,
  getSubscriptionTrialDays,
  isSubscriptionStatusAtLimit,
  isSubscriptionStatusBelowLimit,
  isSubscriptionStatusCanceled,
  isSubscriptionStatusDuringTrial,
  isSubscriptionStatusInvalid,
  isSubscriptionStatusTrialEnded
} from '../../utils/subscription';
import { trackCardSubmission } from '../../utils/tracking';
import StripeCreditCardForm from '../subscription/StripeCreditCardForm';
import withStripeForm from '../subscription/WithStripeForm';

class StripeSubscriptionAccount extends Component {
  state = {
    localSubscription: {},
    localSubscriptionInvoices: [],
    cancelSubscriptionLoading: null,
    showPaymentForm: false,
    quantityForSubscriptionUpdate: null,
    quantityForSubscriptionUpdateSuccess: null,
    quantityForSubscriptionUpdateError: null,
    quantityForSubscriptionUpdateLoading: null,
    subscriptionModals: {}
  };

  componentDidMount = () => {
    this.updateSubscription();
    this.updateSubscriptionInvoices();
  };

  handleLocalSubmit = async event => {
    event.preventDefault();

    let stripeData = null;
    if (
      typeof this.props.getStripeFormComponent === 'function' &&
      !!this.props.getStripeFormComponent() &&
      typeof this.props.getStripeFormComponent().handleSubmit === 'function'
    ) {
      stripeData = await this.props
        .getStripeFormComponent()
        .handleSubmit(event);
    }

    if (
      !stripeData ||
      (!!stripeData &&
        (!stripeData.paymentMethod ||
          (!!stripeData.paymentMethod && !stripeData.paymentMethod.id)))
    ) {
      // error
      return;
    }
  };

  updateSubscription = () => {
    this.props
      .retrieveSubscription(this.props.organization)
      .then(subscription =>
        this.setState({
          localSubscription: subscription,
          showPaymentForm: false,
          quantityForSubscriptionUpdate:
            !!subscription && !!subscription.quantity
              ? subscription.quantity
              : null,
          quantityForSubscriptionUpdateSuccess: null,
          quantityForSubscriptionUpdateError: null,
          quantityForSubscriptionUpdateLoading: null,
          subscriptionModals: {}
        })
      );
  };

  updateSubscriptionInvoices = () => {
    this.props
      .retrieveSubscriptionInvoices(this.props.organization)
      .then(invoices =>
        this.setState({
          localSubscriptionInvoices: invoices
        })
      );
  };

  handlePaySubscriptionInvoice = (event, invoiceId) => {
    this.props
      .paySubscriptionInvoice(this.props.organization, invoiceId)
      .then(invoices =>
        this.setState({
          localSubscriptionInvoices: invoices
        })
      );
  };

  setShow = (organization, value) => {
    this.setState({
      subscriptionModals: {
        ...this.state.subscriptionModals,
        [organization['@id']]: value
      }
    });
  };

  handleClose = organization => this.setShow(organization, false);

  handleShow = organization => this.setShow(organization, true);

  setQuantity = quantity => {
    if (isNaN(quantity)) {
      return;
    }
    if (quantity < 1) {
      return;
    }

    this.setState({
      quantityForSubscriptionUpdate: quantity
    });
  };

  decrementQuantity = event => {
    const currentQuantity = this.state.quantityForSubscriptionUpdate || 0;

    this.setQuantity(parseInt(currentQuantity) - 1);
  };

  incrementQuantity = event => {
    const currentQuantity = this.state.quantityForSubscriptionUpdate || 0;

    this.setQuantity(parseInt(currentQuantity) + 1);
  };

  handleUpdateSubscriptionQuantity = event => {
    const quantity = this.state.quantityForSubscriptionUpdate;
    if (isNaN(quantity)) {
      return;
    }
    if (quantity < 1) {
      return;
    }

    this.setState({
      quantityForSubscriptionUpdateLoading: true
    });

    this.props
      .updateSubscriptionQuantity(this.props.organization, quantity)
      .then(subscription =>
        this.setState({
          localSubscription: subscription,
          showPaymentForm: false,
          quantityForSubscriptionUpdate:
            !!subscription && !!subscription.quantity
              ? subscription.quantity
              : null,
          quantityForSubscriptionUpdateSuccess: true,
          quantityForSubscriptionUpdateError: null,
          quantityForSubscriptionUpdateLoading: null,
          subscriptionModals: {}
        })
      )
      .catch(e =>
        this.setState({
          quantityForSubscriptionUpdate:
            this.state.localSubscription.quantity || null,
          quantityForSubscriptionUpdateSuccess: null,
          quantityForSubscriptionUpdateError: e.message,
          quantityForSubscriptionUpdateLoading: null
        })
      );
  };

  handleCancelSubscription = event => {
    this.setState({
      cancelSubscriptionLoading: true
    });

    this.props
      .cancelSubscription(this.props.organization)
      .then(subscription =>
        this.setState({
          localSubscription: subscription,
          cancelSubscriptionLoading: null,
          showPaymentForm: false,
          quantityForSubscriptionUpdate: null,
          quantityForSubscriptionUpdateSuccess: null,
          quantityForSubscriptionUpdateError: null,
          quantityForSubscriptionUpdateLoading: null,
          subscriptionModals: {}
        })
      )
      .then(() => {
        // make sure the current user and subscription data is up-to-date
        this.props.retrieveCurrentAccount();
      });
  };

  handleReactivateSubscription = (event, paymentMethodId) => {
    if (!!paymentMethodId) {
      const paymentMethod = {
        id: paymentMethodId
      };
      this.props.reactivateSubscription(paymentMethod).then(retrieved => {
        this.updateSubscription();
        this.updateSubscriptionInvoices();

        if (
          typeof this.props.getStripeFormComponent === 'function' &&
          !!this.props.getStripeFormComponent() &&
          typeof this.props.getStripeFormComponent().paymentUpdated ===
            'function'
        ) {
          this.props
            .getStripeFormComponent()
            .paymentUpdated(retrieved, paymentMethod);
        }
      });
    }
  };

  handleChangePaymentProfile = event => {
    this.setState({ showPaymentForm: !this.state.showPaymentForm });
  };

  formatDate = timestamp => {
    if (!isNaN(timestamp) && timestamp > 0) {
      const date = new Date(timestamp * 1000);

      return date.toDateString();
    }

    return null;
  };

  formatPrice = price => {
    if (!isNaN(price)) {
      return '$' + (price / 100).toFixed(2);
    }

    return null;
  };

  showCancelButton = subscription => {
    if (
      (subscription.hasOwnProperty('paymentMethodId') &&
        !subscription.paymentMethodId) ||
      (!subscription.hasOwnProperty('paymentMethodId') &&
        subscription.status &&
        'canceled' === subscription.status)
    ) {
      return false;
    }

    return true;
  };

  getIsPaymentUpdateSubmitting = () => {
    if (
      typeof this.props.getStripeFormComponent === 'function' &&
      !!this.props.getStripeFormComponent() &&
      typeof this.props.getStripeFormComponent().isPaymentSubmitting ===
        'function'
    ) {
      return this.props.getStripeFormComponent().isPaymentSubmitting();
    }

    return false;
  };

  getIsPaymentUpdateSuccess = () => {
    if (
      typeof this.props.getStripeFormComponent === 'function' &&
      !!this.props.getStripeFormComponent() &&
      typeof this.props.getStripeFormComponent().isPaymentSuccess === 'function'
    ) {
      return this.props.getStripeFormComponent().isPaymentSuccess();
    }

    return false;
  };

  render() {
    const subscription =
      this.state.localSubscription ||
      this.props.user['@_organizationCurrentSubscription'] ||
      {};

    const subscriptionInvoices = this.state.localSubscriptionInvoices || [];

    const trialDays = getSubscriptionTrialDays(subscription);

    const location = this.props.location || {};
    // @see \App\Controller\StripeController::sendTrialWillEndEmailForUser()
    const isTrialWillEnd =
      (location.search || '').indexOf('trial_will_end=1') > -1;

    const organizationId = this.props.organization['@id'] || '';

    const isCurrentUserEnabled =
      this.props.user['@_organizationCurrentEnabledStatus'] || false;

    const nextInvoiceDate = getSubscriptionNextInvoiceDate(subscription);

    const showCancel = this.showCancelButton(subscription);

    const isPaymentUpdateSubmitting = this.getIsPaymentUpdateSubmitting();
    const isPaymentUpdateSuccess = this.getIsPaymentUpdateSuccess();

    return (
      <div className={'page-list page-account mt-3'}>
        <div className={'page-actions-wrapper'}>
          <div className={'page-actions'}>
            {subscription &&
              subscription.status &&
              'canceled' !== subscription.status &&
              isAdminForOrganization(organizationId) && (
                <>
                  <button
                    className={'btn btn-primary cancel-subscription'}
                    onClick={() =>
                      this.handleShow({
                        '@id': 'cancel_subscription_' + organizationId
                      })
                    }
                  >
                    Cancel Subscription
                  </button>
                  <Modal
                    show={
                      this.state.subscriptionModals[
                        'cancel_subscription_' + organizationId
                      ] || false
                    }
                    onHide={() =>
                      this.handleClose({
                        '@id': 'cancel_subscription_' + organizationId
                      })
                    }
                    centered
                    size="xl"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>
                        Are you sure you want to cancel your subscription for
                        this Organization?
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>This will:</p>
                      <ul>
                        <li>Cancel the Subscription</li>
                        <li>Deactivate All Users in this Organization</li>
                        <li>Deactivate All Managers in this Organization</li>
                        <li>
                          Allow Admins to re-subscribe in this Organization
                        </li>
                      </ul>
                    </Modal.Body>
                    <Modal.Footer>
                      <button
                        className={'btn'}
                        onClick={() =>
                          this.handleClose({
                            '@id': 'cancel_subscription_' + organizationId
                          })
                        }
                        disabled={this.state.cancelSubscriptionLoading}
                      >
                        Do Nothing
                      </button>
                      <button
                        className={'btn btn-danger'}
                        onClick={event => this.handleCancelSubscription(event)}
                        disabled={this.state.cancelSubscriptionLoading}
                      >
                        {this.state.cancelSubscriptionLoading ? (
                          <span>
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            >
                              {''}
                            </span>{' '}
                            Cancelling...
                          </span>
                        ) : (
                          'Cancel Subscription Now'
                        )}
                      </button>
                    </Modal.Footer>
                  </Modal>
                </>
              )}
          </div>
        </div>

        <nav className={'nav'}>
          <h1>Manage Account</h1>
        </nav>

        {isPaymentUpdateSuccess && (
          <div className="alert alert-success" role="status">
            The Payment Profile has been updated.
          </div>
        )}

        {this.state.quantityForSubscriptionUpdateSuccess && (
          <div className="alert alert-success" role="status">
            The Subscription Seat Count has been updated.
          </div>
        )}

        {this.state.quantityForSubscriptionUpdateError && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.state.quantityForSubscriptionUpdateError}
          </div>
        )}

        {subscription && isSubscriptionStatusInvalid(subscription) && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            Your subscription is currently inactive, please supply payment info
            to reactivate.
          </div>
        )}

        {(!subscription || !subscription.status) && (
          <div className="pull-left" role="status">
            <div style={{ position: 'relative' }}>
              <div style={{ position: 'absolute', top: 0, left: 0 }}>
                Loading...
              </div>
            </div>
          </div>
        )}

        <div className={'manage-subscription row'}>
          {subscription && (
            <div className={'col-sm-12 col-md-6 col-lg-4'}>
              <div className={'subscription-card card mb-2'}>
                <div className={'card-body'}>
                  <h2 className={'card-title h5 font-weight-bold'}>
                    {isSubscriptionStatusInvalid(subscription)
                      ? 'Inactive Subscription'
                      : 'Subscription'}
                  </h2>
                  <hr />
                  {isAdminForOrganization(organizationId) &&
                    subscription &&
                    subscription.quantity &&
                    subscription.unit_amount &&
                    subscription.interval_count &&
                    subscription.interval && (
                      <div className={'subscription-info'}>
                        <div className={'subscription-seat-counts row'}>
                          <div className={'col mb-1'}>
                            <div
                              className={
                                'subscription-seat-count-total text-center bg-white border-dark rounded shadow-sm p-3'
                              }
                              style={
                                isSubscriptionStatusDuringTrial(subscription)
                                  ? { display: 'none' }
                                  : undefined
                              }
                            >
                              <div className={'h4'}>
                                <strong>
                                  {isSubscriptionStatusDuringTrial(subscription)
                                    ? 'Unlimited'
                                    : isSubscriptionStatusCanceled(subscription)
                                    ? 1
                                    : subscription.quantity}
                                </strong>
                              </div>
                              <div>Plan Seats</div>
                            </div>
                          </div>
                          <div className={'col mb-1'}>
                            <div
                              className={
                                'subscription-seat-count-used text-center bg-white border-dark rounded shadow-sm p-3'
                              }
                            >
                              <div className={'h4'}>
                                <strong>
                                  {isSubscriptionStatusCanceled(subscription)
                                    ? 1
                                    : subscription.used_quantity}
                                </strong>
                              </div>
                              <div>Used Seats</div>
                            </div>
                          </div>
                          <div className={'col mb-1'}>
                            <div
                              className={
                                'subscription-seat-count-available text-center bg-white border-dark rounded shadow-sm p-3'
                              }
                              style={
                                isSubscriptionStatusDuringTrial(subscription)
                                  ? { display: 'none' }
                                  : undefined
                              }
                            >
                              <div className={'h4'}>
                                <strong>
                                  {isSubscriptionStatusDuringTrial(subscription)
                                    ? 'Unlimited'
                                    : isSubscriptionStatusCanceled(subscription)
                                    ? 0
                                    : subscription.quantity -
                                      subscription.used_quantity}
                                </strong>
                              </div>
                              <div>Available Seats</div>
                            </div>
                          </div>
                        </div>

                        <div className={'subscription-price text-center mt-3'}>
                          <strong>
                            {this.formatPrice(
                              isSubscriptionStatusCanceled(subscription)
                                ? subscription.unit_amount *
                                    subscription.interval_count *
                                    1
                                : subscription.unit_amount *
                                    subscription.interval_count *
                                    subscription.quantity
                            )}
                            /{subscription.interval}
                          </strong>
                          &nbsp;
                          <span>
                            ({this.formatPrice(subscription.unit_amount)}/seat)
                          </span>
                        </div>
                        {nextInvoiceDate &&
                          !isSubscriptionStatusCanceled(subscription) && (
                            <div
                              className={
                                'subscription-next-invoice-date text-center'
                              }
                            >
                              <small className={'text-muted'}>
                                Your next invoice will be charged on{' '}
                                {nextInvoiceDate.toDateString()}.
                              </small>
                            </div>
                          )}

                        <hr />
                      </div>
                    )}

                  {!isCurrentUserEnabled && (
                    <div className={'user-status'}>
                      <p>
                        You do not have an active account with this
                        organization. You can use the menu above to switch
                        organizations or create a new one.
                      </p>
                    </div>
                  )}

                  {subscription && (
                    <div className={'subscription-status'}>
                      {/* during trial */}
                      {isAdminForOrganization(organizationId) &&
                        isSubscriptionStatusDuringTrial(subscription) &&
                        !isTrialWillEnd && (
                          <p>
                            You have {trialDays} day{trialDays === 1 ? '' : 's'}{' '}
                            left in your trial account. Enjoy!
                          </p>
                        )}
                      {/* trial will end */}
                      {isAdminForOrganization(organizationId) &&
                        isSubscriptionStatusDuringTrial(subscription) &&
                        isTrialWillEnd && (
                          <p>
                            Your trial is about to expire, please select the
                            number of seats you want and provide payment
                            information if you'd like to continue using Lista.
                          </p>
                        )}
                      {/* at limit */}
                      {isAdminForOrganization(organizationId) &&
                        !isSubscriptionStatusCanceled(subscription) &&
                        isSubscriptionStatusAtLimit(subscription) && (
                          <p>
                            Your subscription allows {subscription.quantity}{' '}
                            seat
                            {subscription.quantity === 1 ? '' : 's'} and you
                            have {subscription.used_quantity} user
                            {subscription.used_quantity === 1 ? '' : 's'}{' '}
                            currently enabled.
                          </p>
                        )}
                      {/* below limit */}
                      {isAdminForOrganization(organizationId) &&
                        !isSubscriptionStatusCanceled(subscription) &&
                        isSubscriptionStatusBelowLimit(subscription) && (
                          <p>
                            Your subscription allows {subscription.quantity}{' '}
                            seat
                            {subscription.quantity === 1 ? '' : 's'} and you
                            have {subscription.used_quantity} user
                            {subscription.used_quantity === 1 ? '' : 's'}{' '}
                            currently enabled. You can invite up to{' '}
                            {subscription.quantity - subscription.used_quantity}{' '}
                            more user
                            {subscription.quantity -
                              subscription.used_quantity ===
                            1
                              ? ''
                              : 's'}
                            .
                          </p>
                        )}
                      {/* trial ended */}
                      {isAdminForOrganization(organizationId) &&
                        isSubscriptionStatusTrialEnded(subscription) && (
                          <p>
                            Your trial subscription has ended, please purchase
                            at least one seat below in order to continue using
                            Lista.
                          </p>
                        )}
                      {/* invalid subscription, i.e. declined / expired CC, or previously cancelled */}
                      {isAdminForOrganization(organizationId) &&
                        isSubscriptionStatusInvalid(subscription) && (
                          <>
                            {subscription &&
                            subscription.quantity &&
                            subscription.unit_amount &&
                            subscription.interval_count &&
                            subscription.interval &&
                            isSubscriptionStatusCanceled(subscription) ? (
                              <p>
                                When you reactivate, your subscription will be
                                reset to 1 seat and you'll be the only active
                                user.
                              </p>
                            ) : (
                              <p>
                                Your subscription is not currently active.
                                Please update your billing information to
                                reactivate it.
                              </p>
                            )}
                          </>
                        )}
                      {!isAdminForOrganization(organizationId) &&
                        (isManagerForOrganization(organizationId) ||
                          isUserForOrganization(organizationId)) &&
                        isSubscriptionStatusInvalid(subscription) && (
                          <p>
                            Your organization’s subscription is not currently
                            active. Please contact an administrator and let them
                            know you need access to Lista!
                          </p>
                        )}
                    </div>
                  )}

                  {isAdminForOrganization(organizationId) &&
                    subscription &&
                    subscription.quantity &&
                    subscription.unit_amount &&
                    subscription.interval_count &&
                    subscription.interval && (
                      <div>
                        {isSubscriptionStatusDuringTrial(subscription) ? (
                          <p>
                            During your trial, you can add as many users as you
                            like. You will be charged for them after your trial
                            ends.
                          </p>
                        ) : isSubscriptionStatusCanceled(subscription) ? (
                          <p>
                            You will be able to add more seats and activate
                            other users afterwards.
                          </p>
                        ) : (
                          <p>
                            If you'd like to purchase more seats please update
                            your subscription below.
                          </p>
                        )}
                      </div>
                    )}

                  {isAdminForOrganization(organizationId) &&
                    !isSubscriptionStatusCanceled(subscription) &&
                    subscription &&
                    subscription.paymentMethodId && (
                      <div
                        className={
                          'update-subscription-quantity-wrapper row mb-3'
                        }
                      >
                        <div className={'col-auto'}>
                          <label
                            htmlFor={'update-subscription-quantity-number'}
                            className={'mr-2 sr-only'}
                          >
                            Update Seats To:
                          </label>
                          <div
                            className={
                              'update-subscription-quantity-group input-group rounded'
                            }
                          >
                            <div className={'input-group-prepend'}>
                              <button
                                className={
                                  'subscription-quantity-decrement btn font-weight-bolder'
                                }
                                onClick={event => this.decrementQuantity(event)}
                              >
                                &ndash;
                              </button>
                            </div>
                            <input
                              id={'update-subscription-quantity-number'}
                              className={'form-control text-center'}
                              type={'text'}
                              onChange={event =>
                                this.setQuantity(event.target.value)
                              }
                              value={this.state.quantityForSubscriptionUpdate}
                            />
                            <div className={'input-group-append'}>
                              <button
                                className={
                                  'subscription-quantity-increment btn font-weight-bolder'
                                }
                                onClick={event => this.incrementQuantity(event)}
                              >
                                +
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className={'col'}>
                          <button
                            className={
                              'btn btn-primary update-subscription-quantity btn-cta'
                            }
                            onClick={event =>
                              this.handleUpdateSubscriptionQuantity(event)
                            }
                            disabled={
                              this.state.quantityForSubscriptionUpdateLoading
                            }
                          >
                            {this.state.quantityForSubscriptionUpdateLoading ? (
                              <span>
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                >
                                  {''}
                                </span>{' '}
                                Updating...
                              </span>
                            ) : (
                              'Update Subscription Seats'
                            )}
                          </button>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          )}

          {isAdminForOrganization(organizationId) && subscription && (
            <div className={'col-sm-12 col-md-6 col-lg-4'}>
              <div className={'payment-profile-card card mb-2'}>
                <div className={'card-body'}>
                  <h2 className={'card-title h5 font-weight-bold'}>
                    Payment Profile
                  </h2>
                  <hr />
                  {isAdminForOrganization(organizationId) &&
                    subscription &&
                    subscription.paymentMethodId &&
                    !!subscription.last4 && (
                      <>
                        <div
                          className={
                            'payment-profile clearfix bg-white border-dark rounded shadow-sm p-4 mb-3'
                          }
                        >
                          <div className={'pull-right'}>
                            <button
                              className={'btn btn-link btn-lg'}
                              onClick={event =>
                                this.handleChangePaymentProfile(event)
                              }
                            >
                              Edit
                            </button>
                          </div>
                          {subscription.card_brand && (
                            <span
                              className={
                                'payment-icon pull-left mr-4 fa fa-3x fa-cc-' +
                                subscription.card_brand
                              }
                            >
                              {''}
                            </span>
                          )}
                          <div className={'payment-info'}>
                            <span>{subscription.card_brand} </span>
                            <span className={'text-nowrap'}>
                              **** {subscription.last4}
                            </span>
                          </div>
                          {subscription.card_exp_month &&
                            subscription.card_exp_year && (
                              <small className={'payment-expiration muted'}>
                                Expires {subscription.card_exp_month}/
                                {subscription.card_exp_year}
                              </small>
                            )}
                        </div>
                        {isSubscriptionStatusInvalid(subscription) && (
                          <div
                            className={'reactivate-payment-profile pull-right'}
                          >
                            <button
                              className={'btn btn-primary'}
                              onClick={event =>
                                this.handleReactivateSubscription(
                                  event,
                                  subscription.paymentMethodId
                                )
                              }
                            >
                              Reactivate Subscription
                            </button>
                          </div>
                        )}
                      </>
                    )}

                  {isAdminForOrganization(organizationId) &&
                    subscription &&
                    subscription.paymentMethodId &&
                    !!subscription.last4 &&
                    this.state.showPaymentForm && (
                      <>
                        <hr />
                        <p>
                          Enter new card details below to{' '}
                          <strong>replace</strong> the saved payment
                          information.
                        </p>
                      </>
                    )}

                  {isAdminForOrganization(organizationId) &&
                    subscription &&
                    ((subscription.hasOwnProperty('paymentMethodId') &&
                      !subscription.paymentMethodId) ||
                      (!subscription.hasOwnProperty('paymentMethodId') &&
                        subscription.status &&
                        'canceled' === subscription.status) ||
                      this.state.showPaymentForm) && (
                      <div className={'payment-method-form-wrapper'}>
                        <form
                          className={'form'}
                          onSubmit={this.handleLocalSubmit}
                        >
                          {/*<input name={'line1'} placeholder={'Address Line 1'} required={true} />*/}
                          {/*<input name={'line2'} placeholder={'Address Line 2'} required={true} />*/}
                          <div className={'form-row'}>
                            <div className={'form-group col'}>
                              <label
                                htmlFor={'payment-city'}
                                className={'sr-only'}
                              >
                                City
                              </label>
                              <input
                                id={'payment-city'}
                                name={'city'}
                                placeholder={'City'}
                                required={true}
                                className={'form-control'}
                              />
                            </div>
                            <div className={'form-group col'}>
                              <label
                                htmlFor={'payment-state'}
                                className={'sr-only'}
                              >
                                State
                              </label>
                              <input
                                id={'payment-state'}
                                name={'state'}
                                placeholder={'State'}
                                required={true}
                                className={'form-control'}
                              />
                            </div>
                            <div className={'form-group col'}>
                              <label
                                htmlFor={'payment-postal-code'}
                                className={'sr-only'}
                              >
                                Postal Code
                              </label>
                              <input
                                id={'payment-postal-code'}
                                name={'postal_code'}
                                placeholder={'Postal Code'}
                                required={true}
                                className={'form-control'}
                              />
                            </div>
                          </div>
                          <div className={'form-row'}>
                            <div className={'form-group col'}>
                              {this.props.stripeFormComponent}
                            </div>
                          </div>
                          <div className={'pull-right ml-2 mb-1'}>
                            <button
                              className={'btn btn-primary submit-card'}
                              type="submit"
                              disabled={isPaymentUpdateSubmitting}
                            >
                              {isPaymentUpdateSubmitting ? (
                                <span>
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  >
                                    {''}
                                  </span>{' '}
                                  Submitting...
                                </span>
                              ) : (
                                'Submit Card'
                              )}
                            </button>
                          </div>
                          {showCancel && (
                            <div className={'pull-right'}>
                              <button
                                className={'btn btn-outline-danger'}
                                onClick={event =>
                                  this.handleChangePaymentProfile(event)
                                }
                                disabled={isPaymentUpdateSubmitting}
                              >
                                Cancel
                              </button>
                            </div>
                          )}
                        </form>
                      </div>
                    )}
                </div>
              </div>
            </div>
          )}
        </div>

        {isAdminForOrganization(organizationId) &&
          subscription &&
          subscriptionInvoices &&
          subscriptionInvoices.length > 0 && (
            <div
              className={'subscription-invoice-table-list-wrapper mt-3 mb-3'}
            >
              <h2 className={'h5 font-weight-bold pt-3 pb-2'}>
                Billing History
              </h2>
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>Invoice Number</th>
                    <th>Start</th>
                    <th>End</th>
                    <th>Total</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {subscriptionInvoices.map(item => (
                    <tr key={item['id']}>
                      <td>
                        {item['hosted_invoice_url'] ? (
                          <a
                            href={item['hosted_invoice_url']}
                            target={'_blank'}
                          >
                            {item['number']}
                          </a>
                        ) : (
                          item['number']
                        )}
                      </td>
                      <td>{this.formatDate(item['period_start'])}</td>
                      <td>{this.formatDate(item['period_end'])}</td>
                      <td>{this.formatPrice(item['total'])}</td>
                      <td>
                        {item['status']}{' '}
                        {'open' === item['status'] &&
                          subscription &&
                          subscription.paymentMethodId && (
                            <button
                              className={'btn btn-primary'}
                              onClick={event =>
                                this.handlePaySubscriptionInvoice(
                                  event,
                                  item['id']
                                )
                              }
                            >
                              Pay Invoice
                            </button>
                          )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
      </div>
    );
  }
}

export default withStripeForm(StripeSubscriptionAccount, StripeCreditCardForm, {
  onPreAttachPaymentMethod: (event, paymentMethod, component) => {
    trackCardSubmission(event, paymentMethod);
  },
  onPrePaymentUpdated: (data, paymentMethod, component) => {
    component.props.getWrappedFormComponent().updateSubscription();
    component.props.getWrappedFormComponent().updateSubscriptionInvoices();
  },
  cardElementOptions: () => {
    return {
      hidePostalCode: true
    };
  },
  cardElementContainerProps: defaultProps => {
    return {
      className: 'form-control h-auto'
    };
  }
});
