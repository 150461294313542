import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { connect } from 'react-redux';
import { change as changeFormField } from 'redux-form';

import { del } from '../../actions/usedprocedure/delete';
import { list, reset } from '../../actions/usedprocedure/list';
import {
  filterSerialize,
  getFilterValues,
  getPageWithFilterValues,
  setFilterValues
} from '../../utils/listFilter';
import CategoryTreeSelectionForm from '../category/UpdateTree';
import CurrentUserCan, { checkCurrentUserCan } from '../CurrentUserCan';
//import { Link } from 'react-router-dom';
import { VersionLink as Link } from '../VersionLink';
import FilterForm from './FilterForm';

class List extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    deletedItem: PropTypes.object,
    list: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  getPageWithCompletedFilter = page => {
    let queryString =
      'completed_status.label=Complete&completed_status.value=complete';
    // let queryString = 'completed_status.value=complete';
    let url = page + (page.indexOf('?') === -1 ? '?' : '&') + queryString;
    return url;
  };

  componentDidMount() {
    this.props.list(
      this.getPageWithCompletedFilter(
        this.getPageWithFilterValues(
          this.props.match.params.page &&
            decodeURIComponent(this.props.match.params.page)
        )
      )
    );
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.page !== nextProps.match.params.page)
      nextProps.list(
        this.getPageWithCompletedFilter(
          this.getPageWithFilterValues(
            nextProps.match.params.page &&
              decodeURIComponent(nextProps.match.params.page)
          )
        )
      );
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  del = item => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      this.props.del(item);
      this.props.list(
        this.getPageWithCompletedFilter(
          this.getPageWithFilterValues(
            this.props.match.params.page &&
              decodeURIComponent(this.props.match.params.page)
          )
        )
      );
    }
  };

  selectRootItem = (event, data, component) => {
    this.props.changeFormField(
      'usedprocedureFilter',
      'procedure.category.id',
      ''
    );

    setFilterValues('lastProcedureCategory', {});

    component.getTreeComponent().getTreeComponent().resetSelectedTreeItem();

    setTimeout(() => {
      component.getTreeComponent().getTreeComponent().clearSelectedTreeItems();
      component.reapplySelectedTreeRootItem();

      let submitButton = document.getElementById('hidden-submit-filter-button');
      if (submitButton) {
        submitButton.click(); // handleBlur
      }

      component.getTreeComponent().getTreeComponent().clearSelectedTreeItems();
      component.reapplySelectedTreeRootItem();
    }, 1000);
  };

  selectTreeItem = (data, index, component) => {
    this.props.changeFormField(
      'usedprocedureFilter',
      'procedure.category.id',
      data['@id']
    );

    setFilterValues('lastProcedureCategory', data);

    setTimeout(() => {
      let submitButton = document.getElementById('hidden-submit-filter-button');
      if (submitButton) {
        submitButton.click(); // handleBlur
      }
    }, 100);
  };

  pressFormSubmit = () => {
    //
  };

  pressFormReset = () => {
    this.setFilterValues({});
    this.localClearSelectedTreeItems();
    this.localReapplySelectedTreeRootItem();

    setFilterValues('lastProcedureCategory', {});

    this.props.list(
      this.getPageWithCompletedFilter(
        this.getPageWithFilterValues(
          this.props.match.params.page &&
            decodeURIComponent(this.props.match.params.page)
        )
      )
    );

    setTimeout(() => {
      this.localClearSelectedTreeItems();
      this.localReapplySelectedTreeRootItem();
    }, 1000);
  };

  // @see TreeFormFields.clearSelectedTreeItems
  localClearSelectedTreeItems = () => {
    // clear existing selected classes
    let selectedElems = document.getElementsByClassName('selected-item');
    Object.keys(selectedElems).map(seIndex => {
      if (!!selectedElems[seIndex]) {
        selectedElems[seIndex].className = selectedElems[
          seIndex
        ].className.replace('selected-item', '');
      }
      return seIndex;
    });
    let selectedParents = document.getElementsByClassName(
      'selected-item-parent'
    );
    Object.keys(selectedParents).map(spIndex => {
      if (!!selectedParents[spIndex]) {
        selectedParents[spIndex].className = selectedParents[
          spIndex
        ].className.replace('selected-item-parent', '');
      }
      return spIndex;
    });
  };

  // @see TreeForm.reapplySelectedTreeRootItem
  localReapplySelectedTreeRootItem = () => {
    // re-apply selected classes
    let selectedEls = document.getElementsByName('name');
    if (selectedEls.length > 0 && selectedEls[0]) {
      let selectedEl = selectedEls[0];
      if (selectedEl.parentNode.className.indexOf('selected-item') === -1) {
        selectedEl.parentNode.className += ' selected-item';
      }
      // input / form-group / tree-root-item
      let parentEl = selectedEl.parentNode.parentNode;
      if (parentEl.className.indexOf('selected-item-parent') === -1) {
        parentEl.className += ' selected-item-parent';
      }
    }
  };

  getSortableHeader = (field, startDirection) => {
    let page = decodeURIComponent(
      this.getPageWithCompletedFilter(
        this.getPageWithFilterValues(
          this.props.retrieved['hydra:view']
            ? this.props.retrieved['hydra:view']['@id']
            : '/used_procedures/completed/'
        )
      )
    );

    let queryString =
      field + '=' + (startDirection === 'desc' ? 'desc' : 'asc');

    if (page.indexOf(queryString) === -1) {
      if (page.indexOf('order[') !== -1) {
        page = page.replace(/[?&]order[[][^\]]+[\]]=(asc|desc)/, '');
      }
      page = page + (page.indexOf('?') === -1 ? '?' : '&') + queryString;
    } else {
      let queryStringAsc = field + '=asc';
      let queryStringDesc = field + '=desc';

      if (page.indexOf(queryStringAsc) !== -1) {
        if (page.indexOf('order[') !== -1) {
          page = page.replace(/[?&]order[[][^\]]+[\]]=(asc|desc)/, '');
        }
        page = page + (page.indexOf('?') === -1 ? '?' : '&') + queryStringDesc;
      } else if (page.indexOf(queryStringDesc) !== -1) {
        if (page.indexOf('order[') !== -1) {
          page = page.replace(/[?&]order[[][^\]]+[\]]=(asc|desc)/, '');
        }
        page = page + (page.indexOf('?') === -1 ? '?' : '&') + queryStringAsc;
      }
    }

    return encodeURIComponent(page);
  };

  getPageWithFilterValues = page => {
    return getPageWithFilterValues(
      'usedprocedureCompletedFilterValues',
      page,
      '/used_procedures'
    );
  };

  getFilterValues = () => {
    return getFilterValues('usedprocedureCompletedFilterValues');
  };

  setFilterValues = values => {
    setFilterValues('usedprocedureCompletedFilterValues', {
      ...values,
      completed_status: { value: 'complete' }
    });
  };

  isFiltered = () => {
    let filtered = this.getFilterValues();

    if (
      //!!filtered.search_text ||
      !!(filtered.procedure && filtered.procedure.name) ||
      !!filtered.author ||
      !!(
        filtered.procedure &&
        filtered.procedure.category &&
        filtered.procedure.category.id
      ) ||
      !!filtered.completed_status ||
      !!(filtered.tags && filtered.tags.length > 0) ||
      !!filtered.is_my_used_procedure
    ) {
      return true;
    }

    return false;
  };

  serialize = (obj, prefix) => {
    return filterSerialize(obj, prefix, (prefix, p) => {
      if (prefix.indexOf('tags') !== -1) {
        return false;
      }
      return true;
    });
  };

  filter = (filtered, values) => {
    this.setFilterValues(values);

    //let queryString = this.serialize(values);

    //this.props.list('/used_procedures?' + queryString);
    this.props.list(
      this.getPageWithCompletedFilter(
        this.getPageWithFilterValues(
          this.props.match.params.page &&
            decodeURIComponent(this.props.match.params.page)
        )
      )
    );
  };

  render() {
    let lastSelectedCategoryId = false;
    let filtered = this.getFilterValues();
    if (
      filtered &&
      filtered.procedure &&
      filtered.procedure.category &&
      filtered.procedure.category.id
    ) {
      lastSelectedCategoryId = filtered.procedure.category.id.replace(
        /[^\d]*/,
        ''
      );
    }

    /*
    let lastProcedureCategory = '';
    let lastProcedureCategoryData = getFilterValues('lastProcedureCategory');
    if (lastProcedureCategoryData && lastProcedureCategoryData.name) {
      lastProcedureCategory = ' in ' + lastProcedureCategoryData.name;
    }
    */

    return (
      <div className={'page-list page-used-procedure-list mt-3'}>
        <div className={'page-actions-wrapper'}>
          <div className={'page-actions'}>
            {/*
            <CurrentUserCan
              perform={'procedure:create'}
              yes={() => (
              <Link to="/procedures/create" className="btn btn-primary">
                Create New Procedure Template
                <span id={'create-new-procedure-category-text'}>
                  {lastProcedureCategory}
                </span>
              </Link>
            )}
            />
            */}
          </div>
        </div>

        <nav className={'nav'}>
          <Link className={'nav-link h1'} to={'/procedures/'}>
            {/* <span>Procedure Templates</span> */}
            <span>Templates</span>
          </Link>
          <Link className={'nav-link h1 '} to={'/used_procedures/'}>
            {/* <h1>Procedures In Use</h1> */}
            <h1>Active Procedures</h1>
          </Link>
          <Link
            className={'nav-link h1 active '}
            to={'/used_procedures/completed/'}
          >
            {/* <h1>Completed Procedures</h1> */}
            <h1>Completed Templates</h1>
          </Link>
        </nav>

        {/*
        {this.props.loading && (
          <div className="alert alert-info">Loading...</div>
        )}
        */}
        {this.props.loading && (
          <div className="pull-right" role="status">
            <div style={{ position: 'relative' }}>
              <div style={{ position: 'absolute', top: 0, left: 0 }}>
                Loading...
              </div>
            </div>
          </div>
        )}
        {this.props.deletedItem && (
          <div className="alert alert-success">
            {this.props.deletedItem['@id']} deleted.
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger">{this.props.error}</div>
        )}

        {/*
        <p>
          <Link to="create" className="btn btn-primary">
            Create
          </Link>
        </p>
        */}

        <div className={'row ml-0 mr-0'}>
          <div className={'col-md-3 ml-0 pl-0 mr-0 pr-0'}>
            <CategoryTreeSelectionForm
              isDraggingEnabled={checkCurrentUserCan(
                this.props.currentUserAccount,
                'category:edit'
              )}
              displayAddButton={checkCurrentUserCan(
                this.props.currentUserAccount,
                'category:create'
              )}
              displayEditItemButton={checkCurrentUserCan(
                this.props.currentUserAccount,
                'category:edit',
                'editable',
                'not_editable'
              )}
              displayDeleteItemButton={checkCurrentUserCan(
                this.props.currentUserAccount,
                'category:delete'
              )}
              displaySelectItemButton={false}
              lastSelectedFieldIndex={lastSelectedCategoryId}
              selectRootItem={this.selectRootItem}
              selectTreeItem={this.selectTreeItem}
              trackSelectTreeItem={true}
              allowEnterKey={false}
            />
          </div>
          <div className={'col-md-9 mr-0 pr-0 ml-0 pl-0'}>
            <div className={'mt-2 mb-2 ml-2 mr-2'}>
              <div className={'filter-form'}>
                <FilterForm
                  onSubmit={values => this.filter(filtered, values)}
                  initialValues={filtered}
                  pressFormSubmit={this.pressFormSubmit}
                  pressFormReset={this.pressFormReset}
                />
              </div>
              {this.props.retrieved &&
              this.props.retrieved['hydra:member'] &&
              this.props.retrieved['hydra:member'].length > 0 ? (
                <div className="usedprocedure-list-table-wrapper">
                  <table className="table table-responsive table-striped table-hover">
                    <thead>
                      <tr>
                        <th>Tags</th>
                        <th>
                          <Link
                            to={this.getSortableHeader(
                              'order[procedure.name]',
                              'asc'
                            )}
                          >
                            Title
                          </Link>
                        </th>
                        {/*<th>Folder</th>*/}
                        <th>Assigned To</th>
                        <th>
                          <Link
                            to={this.getSortableHeader(
                              'order[createdAt]',
                              'asc'
                            )}
                          >
                            Started
                          </Link>
                        </th>
                        <th>
                          <Link
                            to={this.getSortableHeader(
                              'order[updatedAt]',
                              'asc'
                            )}
                          >
                            Updated
                          </Link>
                        </th>
                        <th>
                          <Link
                            to={this.getSortableHeader('order[dueAt]', 'asc')}
                          >
                            Due Date
                          </Link>
                        </th>
                        <th>Progress</th>
                        <th colSpan={2}>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.retrieved &&
                        this.props.retrieved['hydra:member'].map(item => (
                          <tr key={item['@id']}>
                            <td>
                              {item['tagNames']
                                .sort((a, b) => {
                                  if (
                                    a.label.toLowerCase() <
                                    b.label.toLowerCase()
                                  )
                                    return -1;
                                  if (
                                    a.label.toLowerCase() >
                                    b.label.toLowerCase()
                                  )
                                    return 1;
                                  return 0;
                                })
                                .map((item, i) => (
                                  <span key={i} className={'tag-name'}>
                                    {item.label}
                                  </span>
                                ))}
                            </td>
                            <th scope="row">
                              <Link
                                to={`/used_procedures/edit/${encodeURIComponent(
                                  item['@id']
                                )}`}
                              >
                                {item['procedureName']}
                              </Link>
                            </th>
                            {/*<td>{item['categoryName']}</td>*/}
                            <td>{item['createdByName']}</td>
                            <td>{item['createdAtDate']}</td>
                            <td>{item['updatedAtDate']}</td>
                            <td>{item['dueAtDate']}</td>
                            <td>
                              <ProgressBar
                                className={
                                  parseFloat(
                                    item['completedProgressPercentage']
                                  ) < 100
                                    ? parseFloat(
                                        item['completedProgressPercentage']
                                      ) < 40
                                      ? 'progress-bar-low'
                                      : 'progress-bar-mid'
                                    : 'progress-bar-completed'
                                }
                                now={item['completedProgressPercentage']}
                              />
                            </td>
                            {/*
                          <td>
                            <Link to={`/used_procedures/show/${encodeURIComponent(item['@id'])}`}>
                              <span className="fa fa-search" aria-hidden="true" />
                              <span className="sr-only">Show</span>
                            </Link>
                          </td>
                          */}
                            <td>
                              {parseFloat(item['completedProgressPercentage']) <
                              100 ? (
                                <Link
                                  className={'primary-action'}
                                  to={`/used_procedures/edit/${encodeURIComponent(
                                    item['@id']
                                  )}`}
                                >
                                  <span
                                    className="fa fa-edit"
                                    aria-hidden="true"
                                  />{' '}
                                  <span>Continue</span>
                                </Link>
                              ) : (
                                <Link
                                  className={'primary-action'}
                                  to={`/used_procedures/edit/${encodeURIComponent(
                                    item['@id']
                                  )}`}
                                >
                                  <span
                                    className="fa fa-eye"
                                    aria-hidden="true"
                                  />{' '}
                                  <span>Review</span>
                                </Link>
                              )}
                            </td>
                            {/*
                          <td>
                            <Link to={`/used_procedures/edit/${encodeURIComponent(item['@id'])}`}>
                              <span className="fa fa-pencil" aria-hidden="true" />
                              <span className="sr-only">Edit</span>
                            </Link>
                          </td>
                          */}
                            <td>
                              <CurrentUserCan
                                perform={'usedprocedure:delete'}
                                yes={() => (
                                  <DropdownButton
                                    id={
                                      'dropdown-basic-button-' +
                                      item['@id'].replace(/[^\d]*/, '')
                                    }
                                    title={
                                      <span className={'fa fa-ellipsis-v'}>
                                        <span className={'sr-only'}>
                                          Actions
                                        </span>
                                      </span>
                                    }
                                  >
                                    {/*
                                {parseFloat(item['completedProgressPercentage']) <
                                  100 && (
                                  <Link
                                    className={'dropdown-item view-action'}
                                    to={`/used_procedures/show/${encodeURIComponent(item['@id'])}`}
                                  >
                                    <span
                                      className="fa fa-eye"
                                      aria-hidden="true"
                                    />{' '}
                                    <span>View</span>
                                  </Link>
                                )}
                                */}
                                    <Link
                                      className={'dropdown-item delete-action'}
                                      to={'.'}
                                      onClick={() => this.del(item)}
                                    >
                                      <span
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      />{' '}
                                      <span>Delete</span>
                                    </Link>
                                  </DropdownButton>
                                )}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className={'no-results'}>
                  {this.isFiltered() ? (
                    // <p>No Procedures In Use match your filter.</p>
                    <p>No Active Procedures match your filter.</p>
                  ) : (
                    // <p>You don't seem to have any procedures in use.</p>
                    <p>You don't seem to have any active procedures.</p>
                  )}
                </div>
              )}

              {this.props.retrieved &&
                this.props.retrieved['hydra:member'] &&
                this.props.retrieved['hydra:member'].length > 0 &&
                this.pagination()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  pagination() {
    const view = this.props.retrieved && this.props.retrieved['hydra:view'];
    if (!view) return;

    const {
      'hydra:first': first,
      'hydra:previous': previous,
      'hydra:next': next,
      'hydra:last': last
    } = view;

    if (!first && !previous && !next && !last) {
      return;
    }

    return (
      <nav aria-label="Page navigation">
        <Link
          to="."
          className={`btn btn-primary${previous ? '' : ' disabled'}`}
        >
          <span aria-hidden="true">&lArr;</span> First
        </Link>
        <Link
          to={
            !previous || previous === first
              ? '.'
              : encodeURIComponent(
                  this.getPageWithCompletedFilter(
                    this.getPageWithFilterValues(previous)
                  )
                )
          }
          className={`btn btn-primary${previous ? '' : ' disabled'}`}
        >
          <span aria-hidden="true">&larr;</span> Previous
        </Link>
        <Link
          to={
            next
              ? encodeURIComponent(
                  this.getPageWithCompletedFilter(
                    this.getPageWithFilterValues(next)
                  )
                )
              : '#'
          }
          className={`btn btn-primary${next ? '' : ' disabled'}`}
        >
          Next <span aria-hidden="true">&rarr;</span>
        </Link>
        <Link
          to={
            last
              ? encodeURIComponent(
                  this.getPageWithCompletedFilter(
                    this.getPageWithFilterValues(last)
                  )
                )
              : '#'
          }
          className={`btn btn-primary${next ? '' : ' disabled'}`}
        >
          Last <span aria-hidden="true">&rArr;</span>
        </Link>
      </nav>
    );
  }

  renderLinks = (type, items) => {
    if (Array.isArray(items)) {
      return items.map((item, i) => (
        <div key={i}>{this.renderLinks(type, item)}</div>
      ));
    }

    return (
      <Link to={`../${type}/show/${encodeURIComponent(items)}`}>{items}</Link>
    );
  };
}

const mapStateToProps = state => {
  const { retrieved, loading, error, eventSource, deletedItem } =
    state.usedprocedure.list;
  return {
    currentUserAccount: state.account.show.retrieved,
    retrieved,
    loading,
    error,
    eventSource,
    deletedItem
  };
};

const mapDispatchToProps = dispatch => ({
  changeFormField: (form, field, value) =>
    dispatch(changeFormField(form, field, value)),
  del: item => dispatch(del(item)),
  list: page => dispatch(list(page)),
  reset: eventSource => dispatch(reset(eventSource))
});

export default connect(mapStateToProps, mapDispatchToProps)(List);
