import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

class Form extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  renderField = data => {
    data.input.className = 'form-control';

    const isInvalid = data.meta.touched && !!data.meta.error;
    if (isInvalid) {
      data.input.className += ' is-invalid';
      data.input['aria-invalid'] = true;
    }

    if (this.props.error && data.meta.touched && !data.meta.error) {
      data.input.className += ' is-valid';
    }

    return (
      <div className={`form-group`}>
        {/*
        <label
          htmlFor={`procedure_${data.input.name}`}
          className="form-control-label"
        >
          {data.input.name}
        </label>
        */}
        <input
          {...data.input}
          type={data.type}
          step={data.step}
          required={data.required}
          placeholder={data.placeholder}
          id={`procedure_${data.input.name}`}
          style={data.style}
          autoFocus={data.autoFocus || false}
        />
        {isInvalid && <div className="invalid-feedback">{data.meta.error}</div>}
      </div>
    );
  };

  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <button
          type="submit"
          className="btn btn-primary pull-right hidden-submit-button"
        >
          Save
        </button>
        <Field
          component={this.renderField}
          name="name"
          type="text"
          //placeholder="What should we name your procedure?"
          placeholder="What should we name your template?"
          required={true}
          style={{
            border: '3px solid #e5e5e5',
            fontWeight: 600,
            textAlign: 'center',
            width: '100%',
            fontSize: '36px'
          }}
          autoFocus={true}
        />
      </form>
    );
  }
}

export default reduxForm({
  form: 'procedure',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(Form);
