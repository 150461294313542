import Tree, {
  type ItemId,
  type RenderItemParams,
  type TreeData,
  type TreeDestinationPosition,
  type TreeItem,
  type TreeSourcePosition,
  moveItemOnTree,
  mutateTree
} from '@atlaskit/tree';
import { debounce } from 'lodash';
import React, { Component } from 'react';

import TreeBuilder from './TreeBuilder';

type State = {|
  tree: TreeData
|};

export default class DragDropWithNestingTree extends Component<void, State> {
  state = {
    wrapperHeight: DragDropWithNestingTree.getWrapperHeight(this.props.tree),
    tree: this.props.tree
  };

  static getWrapperHeight = tree => {
    if (tree && tree.items) {
      let count = 0;
      Object.keys(tree.items).forEach(itemId => {
        if (
          tree.rootId !== itemId &&
          DragDropWithNestingTree.isTreeItemVisible(tree, itemId)
        ) {
          count++;
        }
      });

      return count * 52;
    }

    return window.innerHeight / 2;
  };

  static isTreeItemVisible = (tree, itemIndex, defaultValue = true) => {
    let sourceItem = tree.items[itemIndex];
    if (sourceItem) {
      if (sourceItem.isRemoved) {
        return false;
      }

      // get parent of source
      let sourceParentItemId = '';
      Object.keys(tree.items).forEach(itemId => {
        if (tree.items[itemId].children.indexOf(sourceItem.id) !== -1) {
          sourceParentItemId = itemId;
        }
      });

      if (tree.rootId === sourceParentItemId) {
        return true;
      }

      if (!sourceParentItemId) {
        return defaultValue;
      }

      if (!tree.items[sourceParentItemId].isExpanded) {
        return false;
      }

      return DragDropWithNestingTree.isTreeItemVisible(
        tree,
        sourceParentItemId,
        tree.items[sourceParentItemId].isExpanded
      );
    }

    return false;
  };

  static getIcon(
    props,
    item: TreeItem,
    onExpand: (itemId: ItemId) => void,
    onCollapse: (itemId: ItemId) => void
  ) {
    if (item.children && item.children.length > 0) {
      return item.isExpanded ? (
        <button
          className={'indent parent-open'}
          onClick={() => onCollapse(item.id)}
          style={{ border: 0 }}
        >
          <span
            className={props.iconParentOpenClass || 'fa fa-chevron-down'}
            onClick={() => onCollapse(item.id)}
          >
            <span className={'sr-only'}>Collapse</span>
          </span>
        </button>
      ) : (
        <button
          className={'indent parent-closed'}
          onClick={() => onExpand(item.id)}
          style={{ border: 0 }}
        >
          <span
            className={props.iconParentClosedClass || 'fa fa-chevron-right'}
            onClick={() => onExpand(item.id)}
          >
            <span className={'sr-only'}>Expand</span>
          </span>
        </button>
      );
    }
    return <span className={'indent is-child'}>{''}</span>;
  }

  renderItem = ({
    item,
    depth,
    onExpand,
    onCollapse,
    provided,
    snapshot
  }: RenderItemParams) => {
    if (item.data && item.data.isRemoved) {
      return '';
    }

    let isDraggingEnabled = this.isDraggingEnabled();
    //let spacerElementWidth = 17; // original width: 35px, split into 34 / 2 = 17
    let disabledDraggingWidth = 15;

    let spacers = [];
    for (let i = depth; i > 0; i--) {
      spacers.push(
        <span
          className={'indent spacer spacer-odd spacer-' + i}
          style={isDraggingEnabled ? {} : { width: disabledDraggingWidth }}
        >
          {''}
        </span>
      );
      spacers.push(
        <span className={'indent spacer spacer-even spacer-' + i}>{''}</span>
      );
    }

    let style = {};
    if (provided.draggableProps && provided.draggableProps.style) {
      style = { ...style, ...provided.draggableProps.style };
    }
    if (provided.dragHandleProps && provided.dragHandleProps.style) {
      style = { ...style, ...provided.dragHandleProps.style };
    }
    if (!snapshot.isDragging) {
      // the max-width: 100% should help with keeping the UsedProcedure isCompleted checkbox visible
      style = { ...style, paddingLeft: '0', maxWidth: '100%' };
    } else {
      //let pad = style.paddingLeft;
      //let newPad = 2 * parseInt(pad);
      //style = { ...style, paddingLeft: newPad };
    }

    return (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        data-item-id={item.id}
        data-is-parent={item.hasChildren}
        data-level={depth}
        className={'item'}
        style={style}
      >
        <span className={'all-spacers'}>{!snapshot.isDragging && spacers}</span>
        <span
          className={
            'indent dragger' +
            (isDraggingEnabled
              ? item.data && item.data.originalId
                ? ' fa fa-arrows-alt'
                : ' fa fa-spinner fa-spin'
              : '') +
            (item.children && item.children.length > 0
              ? ' is-parent-dragger'
              : ' is-child-dragger')
          }
          style={
            isDraggingEnabled
              ? { color: snapshot.isDragging ? '#b33d31' : '#cccccc' }
              : { paddingLeft: disabledDraggingWidth }
          }
        />
        {DragDropWithNestingTree.getIcon(
          this.props,
          item,
          onExpand,
          onCollapse
        )}
        <span
          className={'tree-item-field tree-item-field-' + depth}
          style={{
            display: 'inline-block'
            // width: itemWidth
          }}
        >
          {item.data ? item.data.field : ''}
        </span>
      </div>
    );
  };

  onExpand = (itemId: ItemId) => {
    const { tree }: State = this.state;

    if (typeof this.props.onItemExpand === 'function') {
      this.props.onItemExpand(itemId);
    }

    const newTree = this.mutateItemData(
      mutateTree(tree, itemId, { isExpanded: true }),
      itemId,
      { isExpanded: true }
    );

    this.setState(
      {
        wrapperHeight: DragDropWithNestingTree.getWrapperHeight(newTree),
        tree: newTree
      },
      this.onTreeUpdated
    );
  };

  onCollapse = (itemId: ItemId) => {
    const { tree }: State = this.state;

    if (typeof this.props.onItemCollapse === 'function') {
      this.props.onItemCollapse(itemId);
    }

    const newTree = this.mutateItemData(
      mutateTree(tree, itemId, { isExpanded: false }),
      itemId,
      { isExpanded: false }
    );

    this.setState(
      {
        wrapperHeight: DragDropWithNestingTree.getWrapperHeight(newTree),
        tree: newTree
      },
      this.onTreeUpdated
    );
  };

  onDragEnd = (
    source: TreeSourcePosition,
    destination: ?TreeDestinationPosition
  ) => {
    const { tree } = this.state;

    if (!destination) {
      return;
    }

    const newTree = moveItemOnTree(tree, source, destination);
    this.setState(
      {
        wrapperHeight: DragDropWithNestingTree.getWrapperHeight(newTree),
        tree: newTree
      },
      () => this.onDragEndTreeUpdated(source, destination)
    );
  };

  onDragEndTreeUpdated = (source, destination) => {
    if (typeof this.props.onDragEndTreeUpdated === 'function') {
      this.props.onDragEndTreeUpdated(
        this.state.tree,
        source,
        destination,
        this
      );
    }
  };

  onTreeUpdated = debounce(
    () => {
      if (typeof this.props.onTreeUpdated === 'function') {
        this.props.onTreeUpdated(this.state.tree);
      }
    },
    1000,
    { maxWait: 5000 }
  );

  onTreeUpdatedReturnObject = debounce(
    () => {
      if (typeof this.props.onTreeUpdated === 'function') {
        this.props.onTreeUpdated(this.state.tree, 'immediate');
      }
    },
    1000,
    { maxWait: 5000 }
  );

  mutateItemData = (tree, itemId: ItemId, newData) => {
    return mutateTree(tree, itemId, {
      data: {
        ...(tree.items[itemId] && tree.items[itemId].data
          ? tree.items[itemId].data
          : {}),
        ...newData
      }
    });
  };

  mutateMultipleItemsData = (tree, itemIds = [], newData) => {
    let newTree = tree;
    itemIds.map(itemId => {
      newTree = this.mutateItemData(newTree, itemId, newData);
      return itemId;
    });

    return newTree;
  };

  updateItemData = (itemId: ItemId, newData) => {
    const { tree }: State = this.state;

    const newTree = this.mutateItemData(tree, itemId, newData);

    this.setState(
      {
        wrapperHeight: DragDropWithNestingTree.getWrapperHeight(newTree),
        tree: newTree
      },
      this.onTreeUpdated
    );
  };

  updateMultipleItemsData = (itemIds = [], newData) => {
    const { tree }: State = this.state;

    const newTree = this.mutateMultipleItemsData(tree, itemIds, newData);

    this.setState(
      {
        wrapperHeight: DragDropWithNestingTree.getWrapperHeight(newTree),
        tree: newTree
      },
      this.onTreeUpdated
    );
  };

  getItem = (itemId: ItemId) => {
    const { tree }: State = this.state;

    const item = tree.items[itemId];
    if (!item) {
      // Item not found
      return;
    }

    return item;
  };

  getItemCount = () => {
    return Object.keys(this.state.tree.items).length - 1 || 0;
  };

  addItem = (id, data) => {
    let newTree = new TreeBuilder(
      this.state.tree.rootId,
      this.state.tree.items,
      true,
      false
    );

    newTree.withLeaf(id, data);

    this.setState(
      {
        wrapperHeight: DragDropWithNestingTree.getWrapperHeight(
          newTree.build()
        ),
        tree: newTree.build()
      },
      this.onTreeUpdated
    );
  };

  addItemForParentAtIndex = (id, parentId, index, data) => {
    let newTree = new TreeBuilder(
      this.state.tree.rootId,
      this.state.tree.items,
      true,
      false
    );

    newTree.withLeafForParentAtIndex(id, parentId, index, data);

    this.setState(
      {
        wrapperHeight: DragDropWithNestingTree.getWrapperHeight(
          newTree.build()
        ),
        tree: newTree.build()
      },
      this.onTreeUpdated
    );
  };

  moveItemBackOneLevelAtIndex = (itemId, index) => {
    let newTree = new TreeBuilder(
      this.state.tree.rootId,
      this.state.tree.items,
      true,
      true /* necessary to cloneDeep to properly show parent indicators */,
      this.props.getFieldValueAtIndex
    );

    let operation = newTree.moveItemBackOneLevelAtIndex(itemId, index);

    this.setState(
      {
        wrapperHeight: DragDropWithNestingTree.getWrapperHeight(
          newTree.build()
        ),
        tree: newTree.build()
      },
      this.onTreeUpdatedReturnObject
    );

    if (operation) {
      return operation;
    }
  };

  moveItemForwardOneLevelAtIndex = (itemId, index) => {
    let newTree = new TreeBuilder(
      this.state.tree.rootId,
      this.state.tree.items,
      true,
      true /* necessary to cloneDeep to properly show parent indicators */,
      this.props.getFieldValueAtIndex
    );

    let operation = newTree.moveItemForwardOneLevelAtIndex(itemId, index);

    this.setState(
      {
        wrapperHeight: DragDropWithNestingTree.getWrapperHeight(
          newTree.build()
        ),
        tree: newTree.build()
      },
      this.onTreeUpdatedReturnObject
    );

    if (operation) {
      return operation;
    }
  };

  removeItem = (id, shallow = false, multiple = false) => {
    if (shallow) {
      if (multiple) {
        this.updateMultipleItemsData(id, { isRemoved: true });

        return;
      }

      this.updateItemData(id, { isRemoved: true });

      return;
    }

    let newTree = new TreeBuilder(
      this.state.tree.rootId,
      this.state.tree.items,
      true,
      true
    );

    newTree.removeItem(id);

    this.setState(
      {
        wrapperHeight: DragDropWithNestingTree.getWrapperHeight(
          newTree.build()
        ),
        tree: newTree.build()
      },
      this.onTreeUpdated
    );
  };

  isDraggingEnabled = () => {
    if (
      typeof this.props.isDraggingEnabled === 'boolean' &&
      !this.props.isDraggingEnabled
    ) {
      return false;
    }

    return true;
  };

  render() {
    const { tree } = this.state;

    return (
      <div
        className={'tree'}
        style={{
          height: !this.isDraggingEnabled() ? 'auto' : this.state.wrapperHeight,
          /* The overflow: auto is necessary to allow a row with children to be dragged without having to close the parent/children display */
          overflow: !this.isDraggingEnabled() ? 'hidden' : 'auto'
        }}
      >
        <Tree
          tree={tree}
          renderItem={this.renderItem}
          onExpand={this.onExpand}
          onCollapse={this.onCollapse}
          onDragEnd={this.onDragEnd}
          offsetPerLevel={35}
          isDragEnabled={this.isDraggingEnabled()}
          isNestingEnabled={true}
        />
      </div>
    );
  }
}
