import React from 'react';

//import { Route } from 'react-router-dom';
import { PrivateLayout as Route } from '../components/PrivateLayout';
import { Create, List, Show, Update } from '../components/procedure/';

export default [
  <Route path="/procedures/create" component={Create} exact key="create" />,
  <Route path="/procedures/edit/:id" component={Update} exact key="update" />,
  <Route path="/procedures/show/:id" component={Show} exact key="show" />,
  <Route path="/procedures/" component={List} exact strict key="list" />,
  <Route path="/procedures/:page" component={List} exact strict key="page" />
];
