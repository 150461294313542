import React, { Component } from 'react';

import TreeFormFields from '../tree/TreeFormFields';

class UsedProcedureStepTreeFormFields extends Component {
  preventFormSubmit = event => {
    return this.getTreeComponent().preventFormSubmit(event);
  };

  attachTree = node => {
    this._usedProcedureStepTree = node;
  };

  getTreeComponent = () => {
    return this._usedProcedureStepTree;
  };

  hasTreeData = data => {
    if (data && data.usedProcedureSteps) {
      return true;
    }

    return false;
  };

  getTreeData = data => {
    if (this.hasTreeData(data)) {
      return data.usedProcedureSteps;
    }

    return [];
  };

  getInitialItemCreationData = objectId => {
    return { name: '', usedProcedure: objectId };
  };

  actionCreateItem = values => {
    return { type: '' };
  };

  actionRetrieveItem = id => {
    return { type: '' };
  };

  actionDeleteItem = (item, index) => {
    return { type: '' };
  };

  actionStoreTree = values => {
    return { type: '' };
  };

  render() {
    return (
      <TreeFormFields
        initialValues={this.props.initialValues}
        change={this.props.change}
        dispatch={this.props.dispatch}
        getButtonComponent={this.props.getButtonComponent}
        hasFirstItem={this.props.hasFirstItem}
        setHasFirstItem={this.props.setHasFirstItem}
        hasTreeData={this.hasTreeData}
        getTreeData={this.getTreeData}
        getInitialItemCreationData={this.getInitialItemCreationData}
        actionCreateItem={this.actionCreateItem}
        actionRetrieveItem={this.actionRetrieveItem}
        actionDeleteItem={this.actionDeleteItem}
        actionStoreTree={this.actionStoreTree}
        fieldIdPrefix={'usedprocedure'}
        fieldName={'usedProcedureSteps'}
        addElementId={'add-used-procedure-step'}
        addElementLabel={'Add a Step'}
        displayAddButton={false}
        displayAddButtonOnTop={false}
        deleteItemConfirmMessage={'Are you sure you want to delete this step?'}
        fieldWrapperClassName={'used-procedure-step procedure-step'}
        fieldPlaceholder={'Please describe this step.'}
        displayFieldContainer={true}
        displayEditItemButton={true}
        displayDeleteItemButton={false}
        displaySelectItemButton={false}
        getEditItemActionComponent={this.props.getEditItemActionComponent}
        getDeleteItemActionComponent={this.props.getDeleteItemActionComponent}
        getSelectItemActionComponent={this.props.getSelectItemActionComponent}
        getAfterItemComponent={this.props.getAfterItemComponent}
        itemWidth={1040 + 20}
        iconParentOpenClass={'fa fa-chevron-down'}
        iconParentClosedClass={'fa fa-chevron-right'}
        isDraggingEnabled={false}
        ref={this.attachTree.bind(this)}
      />
    );
  }
}

export default UsedProcedureStepTreeFormFields;
